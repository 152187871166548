/* eslint-disable default-case */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-self-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect, useContext } from "react";
import "./ToolSetStyles.scss";
import texti from "../../../assets/images/workspace/icons/textb.png";
import texth from "../../../assets/images/workspace/icons/textbh.png";
import textd from "../../../assets/images/workspace/icons/textbd.png";
import designi from "../../../assets/images/workspace/icons/designb.png";
import designh from "../../../assets/images/workspace/icons/designbh.png";
import designd from "../../../assets/images/workspace/icons/designbd.png";
import imagei from "../../../assets/images/workspace/icons/imageb.png";
import imageh from "../../../assets/images/workspace/icons/imagebh.png";
import imaged from "../../../assets/images/workspace/icons/imagebd.png";
import inpainti from "../../../assets/images/workspace/icons/inpainti.png";
import inpainth from "../../../assets/images/workspace/icons/inpainth.png";
import inpaintd from "../../../assets/images/workspace/icons/inpaintd.png";
import shapei from "../../../assets/images/workspace/icons/shapei.png";
import shapeh from "../../../assets/images/workspace/icons/shapeh.png";
import shaped from "../../../assets/images/workspace/icons/shaped.png";
import eraseri from "../../../assets/images/workspace/icons/eraseri.png";
import eraserh from "../../../assets/images/workspace/icons/eraserh.png";
import eraserd from "../../../assets/images/workspace/icons/eraserd.png";
import videoa from "../../../assets/images/workspace/icons/video_a.png";
import videoh from "../../../assets/images/workspace/icons/video_h.png";
import videod from "../../../assets/images/workspace/icons/video_d.png";
import extendi from "../../../assets/images/workspace/icons/extend-a.png";
import extendh from "../../../assets/images/workspace/icons/extend-h.png";
import extendd from "../../../assets/images/workspace/icons/extend-d.png";
import characteri from "../../../assets/images/workspace/icons/character-a.png";
import characterh from "../../../assets/images/workspace/icons/character-h.png";
import characterd from "../../../assets/images/workspace/icons/character-d.png";
import upscalei from "../../../assets/images/workspace/icons/upscaleb.png";
import upscaleh from "../../../assets/images/workspace/icons/upscalebh.png";
import upscaled from "../../../assets/images/workspace/icons/upscalebd.png";
import HDi from "../../../assets/images/workspace/icons/HDb.png";
import HDh from "../../../assets/images/workspace/icons/HDh.png";
import HDd from "../../../assets/images/workspace/icons/HDd.png";
import horizontal from "../../../assets/images/workspace/icons/verti.png";
import vertical from "../../../assets/images/workspace/icons/hori.png";
import baseicon from "../../../assets/images/workspace/icons/base-icon.png";
import intro from "../../../assets/images/workspace/icons/intro.png";
import modificationLevelImage from "../../../assets/images/hover-tips-images/modification-level.jpg";
import renderimage from "../../../assets/images/hover-tips-images/base-image-for-render.png";
import maskimage from "../../../assets/images/hover-tips-images/mask.png";
import upscaleimage from "../../../assets/images/hover-tips-images/base-image-for-4x.png";
import preserveimage from "../../../assets/images/hover-tips-images/preserve_image.jpg";
import controlTypeLevel from "../../../assets/images/hover-tips-images/control-type-control-level.png";
import extendinfo from "../../../assets/images/hover-tips-images/extendinfo.jpg";
import upscaleinfo from "../../../assets/images/hover-tips-images/upscaleinfo.jpg";
import characterinfo from "../../../assets/images/hover-tips-images/characterinfo.jpg";
import imageresolutionimage from "../../../assets/images/hover-tips-images/image-resolution.jpg";
import controlanim from "../../../assets/images/control_inpaint_anim.gif";
import inpaintimage from "../../../assets/images/hover-tips-images/inpaint.jpg";
import eraserimage from "../../../assets/images/hover-tips-images/eraser.jpg";
import controlinpaintimage from "../../../assets/images/hover-tips-images/control_reference_image.jpg";
import video from "../../../assets/images/hover-tips-images/video.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import {
  computePostProcessedDimension,
  uploadFile,
  processImage,
  useCreateDefaultMask,
  handleBase64Data,
  createMaskWithCNImage,
  SCALE_LIMIT,
  processImage1,
  computePostProcessedDimension1,
} from "../../../utils/workspaceUtils";
import { CallAwsUrl } from "../../../utils/apiCalls";
import { resetMask } from "../../WorkspaceComponents/MaskComponent/ImageMaskPopUp";
import LearnPopups from "../../TipsPopup/TipsPopups";
import { RenderToolsetContext } from "../../../store/RenderToolsetData";
import { EditToolsetContext } from "../../../store/EditToolsetData";
import { UpscaleToolsetContext } from "../../../store/UpscaleToolsetData";
import { DesignLibraryContext } from "../../../store/DesignLibraryData";
import { ToolsetDataContext } from "../../../store/WorkSpaceToolsetData";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import Portal from "../../TipsPopup/Portal1";
import HoverTip from "../../TipsPopup/HoverTip";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import { VideoToolsetContext } from "../../../store/VideoToolsetData";

function ToolSetComponent({
  setIsButtonDisabled,
  setIsLimitPopup,
  setGlobalErrorMessage,
  handleActiveLearn,
  activeLearnBool,
  setActiveLearnBool,
  setIsUploadScaled,
  setTemplateDisable,
  setIsActiveToolset,
  isActiveToolset,
  controlFocus,
  setControlFocus,
}) {
  const { t } = useTranslation();
  const labels = {
    "-4": `${t("upscale_vertical")} +100%`,
    "-3": `${t("upscale_vertical")} +80%`,
    "-2": `${t("upscale_vertical")} +60%`,
    "-1": `${t("upscale_vertical")} +40%`,
    1: `${t("upscale_horizontal")} +40%`,
    2: `${t("upscale_horizontal")} +60%`,
    3: `${t("upscale_horizontal")} +80%`,
    4: `${t("upscale_horizontal")} +100%`,
  };
  const values = {
    "-4": -100,
    "-3": -80,
    "-2": -60,
    "-1": -40,
    1: 40,
    2: 60,
    3: 80,
    4: 100,
  };
  const params = useParams();
  const imgRef = useRef(null);
  const createDefaultMask = useCreateDefaultMask();
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const sectionsRef = useRef(null);

  const { toolsetData, updateToolsetData } = useContext(ToolsetDataContext);
  const {
    renderToolsetData,
    updateRenderToolsetData,
    clearRenderToolsetData,
    updateRenderToolsetData1,
    clearRenderToolsetData1,
  } = useContext(RenderToolsetContext);
  const { editToolsetData, updateEditToolsetData, clearEditToolsetData } =
    useContext(EditToolsetContext);
  const {
    upscaleToolsetData,
    updateUpscaleToolsetData,
    clearUpscaleToolsetData,
  } = useContext(UpscaleToolsetContext);
  const {
    videoToolsetData,
    updateVideoToolsetData,
    clearVideoToolsetData,
  } = useContext(VideoToolsetContext);
  const { designLibraryData, updateDesignLibraryData } =
    useContext(DesignLibraryContext);
  const { responsiveData } = useContext(ResponsiveDataContext);

  const languageActive = localStorage.getItem("language");
  let dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  const atmosphere = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const toolsetLocal = JSON.parse(
    localStorage.getItem(`toolsetData_${lastPart}`)
  );
  const enhanceLocal = JSON.parse(
    localStorage.getItem(`enhanceData_${lastPart}`)
  );
  const isLoginUser = localStorage.getItem("loginUser");
  const exampleprojectsJson = JSON.parse(
    localStorage.getItem("exampleProjects")
  );
  const designLocalBase = JSON.parse(
    localStorage.getItem(`designBaseimg_${lastPart}`)
  );
  const upscaleLocalBase = JSON.parse(
    localStorage.getItem(`upscaleBaseimg_${lastPart}`)
  );
  const videoAILocalBase = JSON.parse(
    localStorage.getItem(`videoAIBaseimg_${lastPart}`)
  );
  const imageCountLocal = localStorage.getItem(
    `imageCount_${lastPart}`,
    JSON.stringify("1")
  );
  let promptLocalData = JSON.parse(
    localStorage.getItem(`promptData_${lastPart}`)
  );
  const localSelection = localStorage.getItem(`selection_${lastPart}`);
  const localEnhanceOption = localStorage.getItem(`enhance-option_${lastPart}`);
  const activeToolsetLocal = JSON.parse(
    localStorage.getItem(`activeToolset_${lastPart}`)
  );
  const imageTypeData = JSON.parse(
    localStorage.getItem(`imageTypeData_${lastPart}`)
  );
  const upscaleTypeData = JSON.parse(
    localStorage.getItem(`upscaleTypeData_${lastPart}`)
  );
  const preserveLocal = JSON.parse(
    localStorage.getItem(`preserve_${lastPart}`)
  );
  const resolutionLocal = JSON.parse(
    localStorage.getItem(`image-resolution_${lastPart}`)
  );
  const imagefidelityLocal = JSON.parse(
    localStorage.getItem(`image-fidelity_${lastPart}`)
  );

  const [activeSubSection, setActiveSubSection] = useState(
    toolsetLocal?.imageRatioName ?? 0
  );
  const [activeOrientation, setActiveOrientation] = useState(
    toolsetLocal?.imageOrientationName ?? 0
  );
  const [activeFidelityLevel, setActiveFidelityLevel] = useState(
    toolsetLocal?.renderFidelityLevel || 5
  );
  const [activePreserveMaterial, setActivePreserveMaterial] = useState(
    toolsetLocal?.preserveMaterial || 1
  );
  const [activeImageFidelityLevel, setActiveImageFidelityLevel] = useState(
    imagefidelityLocal?.id || 1
  );
  const [activeMaskFidelityLevel, setActiveMaskFidelityLevel] = useState(
    toolsetLocal?.maskFidelityLevel || 5
  );
  const [activeExtendFidelityLevel, setActiveExtendFidelityLevel] = useState(
    enhanceLocal?.extendFidelityLevel || 1
  );
  const [extendValue, setExtendValue] = useState(
    `${t("upscale_horizontal")} +40%`
  );
  const [popupVisible, setPopupVisible] = useState(false);
  const [videoResetKey, setVideoResetKey] = useState(0);
  const [inputKey, setInputKey] = useState(0);
  const [imageCount, setImageCount] = useState(undefined);
  const [isVisualizedMaskUpdating, setIsVisualizedMaskUpdating] =
    useState(false);
  const [selection, setSelection] = useState(localSelection ?? "1");
  const [imageType, setImageType] = useState(
    localStorage.getItem(`imageTypeData_${lastPart}`)?.id ?? 1
  );
  const [resolutionType, setResolutionType] = useState(
    localStorage.getItem(`image-resolution_${lastPart}`)?.id ?? 1
  );
  const [upscaleType, setUpscaleType] = useState(
    localStorage.getItem(`upscaleTypeData_${lastPart}`)?.id ?? 1
  );
  const [preserve, setPreserve] = useState(
    JSON.parse(localStorage.getItem(`preserve_${lastPart}`))?.valid ?? false
  );
  const [imageResolution, setImageResolution] = useState(
    JSON.parse(localStorage.getItem(`image-resolution_${lastPart}`))?.valid ??
      false
  );
  const [imageFidelity, setImageFidelity] = useState(
    JSON.parse(localStorage.getItem(`image-fidelity_${lastPart}`))?.valid ??
      false
  );
  const [activeLearnBool1, setActiveLearnBool1] = useState([
    { id: 1, boolean: false },
  ]);
  const [enhanceOption, setEnhanceOption] = useState(localEnhanceOption ?? "1");
  const [percentage, setPercentage] = useState(40);
  const [value, setValue] = useState(false);

  const handleChange = () => {
    setValue(!value);
  };

  useEffect(() => {
    updateEditToolsetData({
      isFromDesignActivated: localSelection == 2,
    });
    setSelection(localSelection);
  }, [localSelection]);

  const isLogin =
    isLoginUser !== "undefined"
      ? JSON.parse(isLoginUser)
      : localStorage.setItem("loginUser", false);

  useEffect(() => {
    clearEditToolsetData();
    clearRenderToolsetData();
    clearRenderToolsetData1();
    clearUpscaleToolsetData();
    clearVideoToolsetData();
    if (localSelection === undefined || localSelection === null) {
      localStorage.setItem(`selection_${lastPart}`, "1");
    }
    if (localEnhanceOption === undefined || localEnhanceOption === null) {
      localStorage.setItem(`enhance-option_${lastPart}`, "1");
    }
    if (activeToolsetLocal === undefined || activeToolsetLocal === null) {
      const newToolsetData = {
        toolsetActive: isActiveToolset ?? 0,
      };
      localStorage.setItem(
        `activeToolset_${lastPart}`,
        JSON.stringify(newToolsetData)
      );
    }
    if (!imageCountLocal) {
      localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(1));
      if (!imageCount) {
        setImageCount(1);
      }
    } else {
      setImageCount(imageCountLocal);
    }
    let imageRatio;
    let imageRatioName;
    let toolset =
      JSON.parse(localStorage.getItem(`toolsetData_${lastPart}`)) || {};

    if (toolset.imageRatioName) {
      if (toolset.imageRatioName === "threetwo") {
        imageRatio = 1;
        imageRatioName = "threetwo";
        setActiveSubSection("threetwo");
      } else if (toolset.imageRatioName === "sixteennine") {
        imageRatio = 3;
        imageRatioName = "sixteennine";
        setActiveSubSection("sixteennine");
      } else if (toolset.imageRatioName === "oneone") {
        imageRatio = 2;
        imageRatioName = "oneone";
        setActiveSubSection("oneone");
      } else if (toolset.imageRatioName === "fourthree") {
        imageRatio = 0;
        imageRatioName = "fourthree";
        setActiveSubSection("fourthree");
      }
    }
    if (toolset.imageRatioName === undefined) {
      imageRatio = 0;
      imageRatioName = "fourthree";
      toolset.imageRatio = 0;
      toolset.imageRatioName = "fourthree";
      setActiveSubSection("fourthree");
    }

    let orientation;
    let imageOrientationName;
    let fidelityLevel;

    if (toolset.imageOrientationName) {
      if (toolset.imageOrientationName === "verti") {
        orientation = 1;
        imageOrientationName = "verti";
        setActiveOrientation("verti");
      } else if (toolset.imageOrientationName === "hori") {
        orientation = 0;
        imageOrientationName = "hori";
        toolset.orientation = 0;
        toolset.imageOrientationName = "hori";
        setActiveOrientation("hori");
      }
    }
    if (toolset.imageOrientationName === undefined) {
      orientation = 0;
      imageOrientationName = "hori";
      toolset.orientation = 0;
      toolset.imageOrientationName = "hori";
      toolset.renderFidelityLevel = 5;
      toolset.editFidelityLevel = 5;
      toolset.maskFidelityLevel = 5;
      toolset.preserveMaterial = 0;
      setActiveOrientation("hori");
    }
    localStorage.setItem(`toolsetData_${lastPart}`, JSON.stringify(toolset));

    if (!imageTypeData) {
      const data2 = {
        name: t("control_line"),
        id: 1,
      };
      localStorage.setItem(`imageTypeData_${lastPart}`, JSON.stringify(data2));
    }

    if (!upscaleTypeData) {
      const data2 = {
        name: t("upscale_creative"),
        id: 2,
      };
      localStorage.setItem(
        `upscaleTypeData_${lastPart}`,
        JSON.stringify(data2)
      );
    }

    const preserve = localStorage.getItem(`preserve_${lastPart}`);
    if (!preserve) {
      const data = {
        valid: false,
      };
      localStorage.setItem(`preserve_${lastPart}`, JSON.stringify(data));
    }

    const enhance = JSON.parse(localStorage.getItem(`enhanceData_${lastPart}`));
    if (!enhance) {
      const data = {
        extendFidelityLevel: 1,
      };
      localStorage.setItem(`enhanceData_${lastPart}`, JSON.stringify(data));
    }
    if (!resolutionLocal) {
      const resolution = {
        id: 1,
        valid: false,
      };
      localStorage.setItem(
        `image-resolution_${lastPart}`,
        JSON.stringify(resolution)
      );
    }
    if (!imagefidelityLocal) {
      const fidelity = {
        id: 1,
        valid: false,
      };
      localStorage.setItem(
        `image-fidelity_${lastPart}`,
        JSON.stringify(fidelity)
      );
    }
  }, []);

  useEffect(() => {
    if (localSelection !== selection) {
      setSelection(localSelection);
    }
  }, [localStorage.getItem(`selection_${lastPart}`)]);

  useEffect(() => {
    if (localEnhanceOption !== enhanceOption) {
      setEnhanceOption(localEnhanceOption);
    }
  }, [localStorage.getItem(`enhance-option_${lastPart}`)]);

  useEffect(() => {
    if (imageTypeData?.id !== imageType) {
      setImageType(imageTypeData?.id);
    }
  }, [localStorage.getItem(`imageTypeData_${lastPart}`)]);

  useEffect(() => {
    let check = JSON.parse(
      localStorage.getItem(`image-resolution_${lastPart}`)
    );
    if (check) {
      if (check?.id !== resolutionType) {
        setResolutionType(check?.id);
        if (
          check?.id === 2 &&
          (imageCountLocal === "3" ||
            imageCountLocal === "4" ||
            imageCountLocal === "2")
        ) {
          setImageCount(1);
          localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(1));
        }
      }
    }
  }, [localStorage.getItem(`image-resolution_${lastPart}`)]);

  useEffect(() => {
    if (upscaleTypeData?.id !== upscaleType) {
      setUpscaleType(upscaleTypeData?.id);
    }
  }, [localStorage.getItem(`upscaleTypeData_${lastPart}`)]);

  useEffect(() => {
    if (activeToolsetLocal?.toolsetActive !== isActiveToolset) {
      const newToolsetData = {
        toolsetActive: isActiveToolset ?? 0,
      };
      updateToolsetData(newToolsetData);
      setIsActiveToolset(activeToolsetLocal?.toolsetActive);
    }
  }, [activeToolsetLocal]);

  const handleFileChangeWithToolsetInput = (toolset) => {
    return (event) => {
      setInputKey((prevKey) => prevKey + 1);
      let imageType = "base-image";
      let bool = false;
      if (toolset === 1) {
        imageType = "cn-image";
        bool = false;
      } else if (toolset === 2) {
        imageType = "base-image";
        bool = false;
      } else if (toolset === 3) {
        imageType = "base-image";
        bool = false;
      } else if (toolset === 4) {
        imageType = "base-image";
        bool = true;
      } else if (toolset === 10) {
        imageType = "cn-image";
        bool = false;
      }
      localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
      const imageUrl = handleFileChange(toolset, event);
      if (imageUrl) {
        updatePreUploadState(toolset, imageUrl);
        setTimeout(() => {
          handleBaseImageData(toolset, imageUrl, bool);
        }, 0);
      }
    };
  };

  const handleBaseImageData = (
    toolset,
    imageUrl,
    bool = false,
    CNImageBlob = null,
    CNImageUrl = null,
    initialCNImageUrl = null
  ) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = function () {
      let resizedWidth, resizedHeight, scale;
      let finalResizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );
      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }

      const proceedNextStep = () => {
        fetch(finalResizedImageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const resizedImageFile = new File([blob], "resized_image.jpeg", {
              type: "image/jpeg",
            });
            uploadFile(
              "base-image",
              bool,
              resizedImageFile,
              async (data) => {
                switch (toolset) {
                  case 0:
                  case 1:
                    updateRenderToolsetData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const renderUpdate = {
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: data?.path,
                    };
                    localStorage.setItem(
                      `designBaseimg_${lastPart}`,
                      JSON.stringify(renderUpdate)
                    );
                    break;
                  case 2:
                    defaultMask = await maskCreationPromise;
                    resetMask(0);
                    updateEditToolsetData({
                      isFromDesignActivated: selection == "2",
                      feedImageBase64: data,
                      uploadedBaseImage: finalResizedImageUrl,
                      maskImageData: defaultMask.visualizedMaskBase64,
                      maskImageBase64: defaultMask.actualMaskData,
                      cnImageBase64: CNImageUrl ? { path: CNImageUrl } : null, //current cn should just be last cn
                      originalCnImageBase64: CNImageUrl
                        ? { path: initialCNImageUrl || CNImageUrl }
                        : null, //but original should be initial cn if it exists
                    });
                    break;
                  case 3:
                    updateUpscaleToolsetData({
                      feedUpBase64: data,
                      isUpScaleURLImgUrl: finalResizedImageUrl,
                    });
                    const upscaleUpdate = {
                      feedUpBase64: data,
                      isUpScaleURLImgUrl: data?.path,
                    };
                    localStorage.setItem(
                      `upscaleBaseimg_${lastPart}`,
                      JSON.stringify(upscaleUpdate)
                    );
                    break;
                  case 4:
                    updateVideoToolsetData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const videoAIUpdate = {
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: data?.path,
                    };
                    localStorage.setItem(
                      `videoAIBaseimg_${lastPart}`,
                      JSON.stringify(videoAIUpdate)
                    );
                    break;
                  case 10:
                    updateEditToolsetData({
                      isFromDesignActivated: true,
                      cnImage: finalResizedImageUrl,
                      cnImageBase64: data,
                      originalCnImageBase64: data,
                      maskImageData: defaultMask.visualizedMaskBase64,
                      // maskImageBase64: defaultMask.actualMaskData,//no need because this always happens after toolset == 2, meaning there will always be a default mask
                    });
                    break;
                  default:
                    break;
                }
              },
              () => setIsButtonDisabled(false),
              (error) => {
                handleRateLimitPopup(error);
                switch (toolset) {
                  case 0:
                  case 1:
                    updateRenderToolsetData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const renderUpdate = {
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    };
                    localStorage.setItem(
                      `designBaseimg_${lastPart}`,
                      JSON.stringify(renderUpdate)
                    );
                    break;
                  case 2:
                    defaultMask = null;
                    resetMask(0);
                    updateEditToolsetData({
                      isFromDesignActivated: selection == "2",
                      feedImageBase64: null,
                      uploadedBaseImage: null,
                      maskImageData: null,
                      maskImageBase64: null,
                      cnImageBase64: null,
                      originalCnImageBase64: null,
                    });
                    break;
                  case 3:
                    updateUpscaleToolsetData({
                      feedUpBase64: null,
                      isUpScaleURLImgUrl: null,
                    });
                    const upscaleUpdate = {
                      feedUpBase64: null,
                      isUpScaleURLImgUrl: null,
                    };
                    localStorage.setItem(
                      `upscaleBaseimg_${lastPart}`,
                      JSON.stringify(upscaleUpdate)
                    );
                    break;
                  case 4:
                    updateVideoToolsetData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const videoAIUpdate = {
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    };
                    localStorage.setItem(
                      `videoAIBaseimg_${lastPart}`,
                      JSON.stringify(videoAIUpdate)
                    );
                    break;
                  case 10:
                    updateEditToolsetData({
                      isFromDesignActivated: true,
                      cnImage: null,
                      cnImageBase64: null,
                      originalCnImageBase64: null,
                      maskImageData: null,
                    });
                    break;
                  default:
                    break;
                }
              }
            );
          })
          .catch((error) => {
            console.error("Error converting Data URL to File:", error);
          });
      };

      //if its first time upload, we shall just create a default mask, where both images are the same size at least
      const maskCreationPromise =
        toolset === 2
          ? createDefaultMask(
              finalResizedImageUrl,
              resizedWidth,
              resizedHeight,
              CNImageBlob,
              selection === "2"
            )
          : null;

      //if its later upload, sizes of the images might not be the same, needs heavy image manipulation therefore need to go through this way
      let defaultMask = null;
      if (toolset === 10) {
        createMaskWithCNImage(
          editToolsetData.uploadedBaseImage,
          editToolsetData.feedImageBase64.width,
          editToolsetData.feedImageBase64.height,
          finalResizedImageUrl,
          resizedWidth,
          resizedHeight,
          editToolsetData.isFromDesignActivated
        )
          .then((result) => {
            defaultMask = result;
            finalResizedImageUrl = defaultMask.CNImageFittedInsideBG;
            proceedNextStep();
          })
          .catch((error) => {
            console.error("Error in creating mask with CN image:", error);
          });
      } else {
        proceedNextStep();
      }
    };
  };

  const handleRateLimitPopup = (error) => {
    updateRenderToolsetData({ uploadedBaseImageUrl: null });
    updateRenderToolsetData1({ uploadedBaseImageUrl: null });
    updateEditToolsetData({ uploadedBaseImage: null });
    updateUpscaleToolsetData({ isUpScaleURLImgUrl: null });
    updateVideoToolsetData({ uploadedBaseImageUrl: null });
    const renderUpdate = {
      uploadedBaseImageUrl: null,
    };
    localStorage.setItem(
      `designBaseimg_${lastPart}`,
      JSON.stringify(renderUpdate)
    );
    const videoUpdate = {
      uploadedBaseImageUrl: null,
    };
    localStorage.setItem(
      `videoAIBaseimg_${lastPart}`,
      JSON.stringify(videoUpdate)
    );
    const upscaleUpdate = {
      isUpScaleURLImgUrl: null,
    };
    localStorage.setItem(
      `upscaleBaseimg_${lastPart}`,
      JSON.stringify(upscaleUpdate)
    );
    setIsLimitPopup(true);
    setGlobalErrorMessage(error.message);
  };

  const updatePreUploadState = (toolset, uploadedUrl) => {
    switch (toolset) {
      case 0:
      case 1:
        updateRenderToolsetData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const renderUpdate = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: uploadedUrl,
        };
        localStorage.setItem(
          `designBaseimg_${lastPart}`,
          JSON.stringify(renderUpdate)
        );
        break;
      case 2:
        updateEditToolsetData({
          uploadedBaseImage: uploadedUrl,
          feedImageBase64: null,
          maskImageData: uploadedUrl,
          maskImageBase64: null,
          isFromDesignActivated: false,
          cnImage: null,
          cnImageBase64: null,
          originalCnImage: null,
          originalCnImageBase64: null,
        });
        break;
      case 3:
        updateUpscaleToolsetData({
          isUpScaleURLImgUrl: uploadedUrl,
          feedUpBase64: null,
        });
        const upscaleUpdate = {
          feedUpBase64: null,
          isUpScaleURLImgUrl: uploadedUrl,
        };
        localStorage.setItem(
          `upscaleBaseimg_${lastPart}`,
          JSON.stringify(upscaleUpdate)
        );
        break;
      case 4:
        updateVideoToolsetData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const videoAIUpdate = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: uploadedUrl,
        };
        localStorage.setItem(
          `videoAIBaseimg_${lastPart}`,
          JSON.stringify(videoAIUpdate)
        );
        break;
      case 10: //for controlnet inpainting
        updateEditToolsetData({
          isFromDesignActivated: true,
          cnImage: uploadedUrl,
          cnImageBase64: null,
          originalCnImage: uploadedUrl,
          originalCnImageBase64: null,
          // maskImageBase64: null,
        });
        break;
      default:
        break;
    }
  };

  const handleFileChange = (toolset, event) => {
    if (!event) {
      console.log(event);
      return null;
    }
    const files = event.target.files || event.dataTransfer.files;
    if (!files || files.length === 0) {
      console.log("No files selected");
      return null;
    }
    setIsButtonDisabled(true);
    const file = files[0];
    if (!file) {
      setIsButtonDisabled(false);
      return null;
    }

    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    if (validTypes.includes(file.type)) {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    } else {
      alert("Please upload an image in JPG or PNG format");
      return null;
    }
  };

  const handleSliderChange = (value) => {
    let toolset = JSON.parse(localStorage.getItem(`toolsetData_${lastPart}`));
    if (toolset.renderFidelityLevel) {
      if (value) {
        toolset.renderFidelityLevel = value;
        setActiveFidelityLevel(value);
      }
      if (value === 0) {
        toolset.renderFidelityLevel = value;
        setActiveFidelityLevel(value);
      }
    } else if (toolset.renderFidelityLevel === 0) {
      if (value) {
        toolset.renderFidelityLevel = value;
        setActiveFidelityLevel(value);
      }
      if (value === 0) {
        toolset.renderFidelityLevel = value;
        setActiveFidelityLevel(value);
      }
    }
    localStorage.setItem(`toolsetData_${lastPart}`, JSON.stringify(toolset));
  };

  useEffect(() => {
    if (toolsetLocal?.renderFidelityLevel) {
      if (toolsetLocal?.renderFidelityLevel !== activeFidelityLevel) {
        setActiveFidelityLevel(toolsetLocal?.renderFidelityLevel);
      }
    }
  }, [toolsetLocal?.renderFidelityLevel]);

  const handlePreserveMaterial = (value) => {
    let toolset = JSON.parse(localStorage.getItem(`toolsetData_${lastPart}`));
    if (toolset.preserveMaterial) {
      if (value) {
        toolset.preserveMaterial = value;
        setActivePreserveMaterial(value);
      }
      if (value === 0) {
        toolset.preserveMaterial = value;
        setActivePreserveMaterial(value);
      }
    } else if (toolset.preserveMaterial === 0) {
      if (value) {
        toolset.preserveMaterial = value;
        setActivePreserveMaterial(value);
      }
      if (value === 0) {
        toolset.preserveMaterial = value;
        setActivePreserveMaterial(value);
      }
    }
    localStorage.setItem(`toolsetData_${lastPart}`, JSON.stringify(toolset));
  };

  useEffect(() => {
    if (toolsetLocal?.preserveMaterial) {
      if (toolsetLocal?.preserveMaterial !== activePreserveMaterial) {
        setActivePreserveMaterial(toolsetLocal?.preserveMaterial);
      }
    }
  }, [toolsetLocal?.preserveMaterial]);

  const handleImageSliderChange = (value) => {
    let check = JSON.parse(localStorage.getItem(`image-fidelity_${lastPart}`));
    if (check?.id) {
      if (value) {
        check.id = value;
        setActiveImageFidelityLevel(value);
      }
    } else {
      if (value) {
        check.id = value;
        setActiveImageFidelityLevel(value);
      }
    }
    localStorage.setItem(`image-fidelity_${lastPart}`, JSON.stringify(check));
  };

  useEffect(() => {
    if (imagefidelityLocal?.id) {
      if (imagefidelityLocal?.id !== activeImageFidelityLevel) {
        setActiveImageFidelityLevel(imagefidelityLocal?.id);
      }
    }
  }, [imagefidelityLocal?.id]);

  const handleMaskSliderChange = (value) => {
    let check = JSON.parse(localStorage.getItem(`toolsetData_${lastPart}`));
    if (check?.maskFidelityLevel) {
      if (value) {
        check.maskFidelityLevel = value;
        setActiveMaskFidelityLevel(value);
      }
      if (value === 0) {
        check.maskFidelityLevel = value;
        setActiveMaskFidelityLevel(value);
      }
    } else if (check?.maskFidelityLevel === 0) {
      if (value) {
        check.maskFidelityLevel = value;
        setActiveMaskFidelityLevel(value);
      }
      if (value === 0) {
        check.maskFidelityLevel = value;
        setActiveMaskFidelityLevel(value);
      }
    }
    localStorage.setItem(`toolsetData_${lastPart}`, JSON.stringify(check));
  };

  useEffect(() => {
    if (toolsetLocal?.maskFidelityLevel) {
      setActiveMaskFidelityLevel(toolsetLocal?.maskFidelityLevel);
    }
  }, [toolsetLocal?.maskFidelityLevel]);

  const handleExtendSliderChange = (value) => {
    let check = JSON.parse(localStorage.getItem(`enhanceData_${lastPart}`));
    if (check?.extendFidelityLevel) {
      if (value) {
        check.extendFidelityLevel = value;
        setActiveExtendFidelityLevel(value);
        setExtendValue(labels[value]);
        setPercentage(values[value]);
      }
      // if (value === 0) {
      //   check.extendFidelityLevel = value;
      //   setActiveExtendFidelityLevel(value);
      //   setExtendValue(labels[value]);
      // }
    } else if (check?.extendFidelityLevel === 0) {
      if (value) {
        check.extendFidelityLevel = value;
        setActiveExtendFidelityLevel(value);
        setExtendValue(labels[value]);
        setPercentage(values[value]);
      }
      // if (value === 0) {
      //   check.extendFidelityLevel = value;
      //   setActiveExtendFidelityLevel(value);
      //   setExtendValue(labels[value]);
      // }
    }
    localStorage.setItem(`enhanceData_${lastPart}`, JSON.stringify(check));
  };

  useEffect(() => {
    if (enhanceLocal?.extendFidelityLevel) {
      setActiveExtendFidelityLevel(enhanceLocal?.extendFidelityLevel);
      setExtendValue(labels[enhanceLocal?.extendFidelityLevel]);
      setPercentage(values[enhanceLocal?.extendFidelityLevel]);
    }
  }, [enhanceLocal?.extendFidelityLevel]);

  const handleOrientation = (active) => {
    const check =
      JSON.parse(localStorage.getItem(`toolsetData_${lastPart}`)) || {};
    let orientation;
    let imageOrientationName;

    if (active === "hori") {
      orientation = 0;
      imageOrientationName = "hori";
      if (check) {
        check.orientation = 0;
        check.imageOrientationName = "hori";
      }
      setActiveOrientation("hori");
    } else if (active === "verti") {
      orientation = 1;
      imageOrientationName = "verti";
      if (check) {
        check.orientation = 1;
        check.imageOrientationName = "verti";
      }
      setActiveOrientation("verti");
    }

    if (active === undefined) {
      orientation = 0;
      imageOrientationName = "hori";
      if (check) {
        check.orientation = 0;
        check.imageOrientationName = "hori";
      }
    }

    if (activeSubSection === undefined) {
      if (check?.imageRatioName) {
        setActiveSubSection(check?.imageRatioName);
      }
    }

    localStorage.setItem(`toolsetData_${lastPart}`, JSON.stringify(check));
  };

  const handleClick = (active, clicked = false) => {
    let imageRatio;
    let imageRatioName;
    const check =
      JSON.parse(localStorage.getItem(`toolsetData_${lastPart}`)) || {};

    if ((clicked = false && check?.imageRatioName)) {
      imageRatio = check.imageRatio;
      imageRatioName = check?.imageRatioName;
      if (check) {
        check.imageRatio = check.imageRatio;
        check.imageRatioName = check?.imageRatioName;
      }
      setActiveSubSection(check?.imageRatioName);
    }

    if (active !== undefined) {
      if (active === "threetwo") {
        imageRatio = 1;
        imageRatioName = "threetwo";
        if (check) {
          check.imageRatio = 1;
          check.imageRatioName = "threetwo";
        }
        setActiveSubSection("threetwo");
      } else if (active === "sixteennine") {
        imageRatio = 3;
        imageRatioName = "sixteennine";
        if (check) {
          check.imageRatio = 3;
          check.imageRatioName = "sixteennine";
        }
        setActiveSubSection("sixteennine");
      } else if (active === "oneone") {
        imageRatio = 2;
        imageRatioName = "oneone";
        if (check) {
          check.imageRatio = 2;
          check.imageRatioName = "oneone";
        }
        setActiveSubSection("oneone");
      } else if (active === "fourthree") {
        imageRatio = 0;
        imageRatioName = "fourthree";
        if (check.imageRatio) {
          check.imageRatio = 0;
          check.imageRatioName = "fourthree";
        }
        setActiveSubSection("fourthree");
      }
    }

    localStorage.setItem(`toolsetData_${lastPart}`, JSON.stringify(check));
  };

  // useEffect(() => {
  //   handleToolsetClick();
  // }, [isActiveToolset]);

  useEffect(() => {
    if (toolsetData?.toolsetActive) {
      setIsActiveToolset(activeToolsetLocal?.toolsetActive ?? 0);
    }
  }, [toolsetData]);

  const handleToolsetClick = (id) => {
    if (id === 1 && resolutionType === 2) {
      setImageCount(1);
      localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(1));
    }
    setIsActiveToolset(id);
    if (toolsetData?.toolsetActive === 3 && id !== 4) {
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else if (id !== 3 && toolsetData?.toolsetActive === 4) {
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else if (id === 3 && localEnhanceOption === "1") {
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else if (id === 4) {
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: true,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    }
    const newToolsetData = {
      toolsetActive: id,
    };
    localStorage.setItem(
      `activeToolset_${lastPart}`,
      JSON.stringify(newToolsetData)
    );
    updateToolsetData(newToolsetData);
    if (id === 2 && selection === "3") {
      const check = {
        isDesign: true,
      };
      setTemplateDisable(true);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    } else if (id === 3 && localEnhanceOption !== "1") {
      const check = {
        isDesign: true,
      };
      setTemplateDisable(true);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    } else {
      const check = {
        isDesign: false,
      };
      setTemplateDisable(false);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event, toolset = null) => {
    event.preventDefault();
    event.stopPropagation();
    let bool = false;
    let imageType = "base-image";
    if (toolset === 1) {
      imageType = "base-image";
    } else if (toolset === 2) {
      imageType = "base-image";
    } else if (toolset === 3) {
      imageType = "base-image";
    } else if (toolset === 4) {
      imageType = "base-image";
      bool = true;
    } else if (toolset === 10) {
      imageType = "cn-image";
    }
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    const selectedToolset =
      toolset !== null && toolset !== undefined ? toolset : isActiveToolset;

    if (toolset === 10 && !editToolsetData?.feedImageBase64) return; // will cause an error if update earlier, try to fix this later

    if (event.dataTransfer.files?.length === 0) {
      const media = JSON.parse(event.dataTransfer.getData("media"));
      const updatedLink = media?.toolset === "video" ? media?.baseImage : media?.url;
      updatePreUploadState(selectedToolset, updatedLink); // clear everything
      if (toolset === 10) {
        CallAwsUrl((res) => {
          //for CN image
          updateEditToolsetData({
            cnImage: handleBase64Data(res.data),
            originalCnImage: handleBase64Data(res.data),
          });
          //now handle CN image and upload
          CallAwsUrl((res) => {
            handleBaseImageData(selectedToolset, handleBase64Data(res.data), bool);
          }, updatedLink);
        }, updatedLink);
      } else if (media?.toolset === "design") {
        //update ahead of time to ensure user will not need to wait, below is in url, so there still is a bit of delay
        CallAwsUrl((res) => {
          //for CN image
          const CNImageBlob = handleBase64Data(res.data);
          updateEditToolsetData({
            cnImage: CNImageBlob,
            originalCnImage: CNImageBlob,
          });
          //now handle base image and upload
          CallAwsUrl((res) => {
            handleBaseImageData(
              selectedToolset,
              handleBase64Data(res.data),
              bool,
              CNImageBlob,
              media?.baseImage
            );
          }, updatedLink);
        }, media?.baseImage);
      } else if (media?.toolset === "image" && media?.originalImage) {
        CallAwsUrl((res) => {
          //for CN image
          const CNImageBlob = handleBase64Data(res.data);
          updateEditToolsetData({
            cnImage: CNImageBlob,
            originalCnImage: CNImageBlob,
          });
          //now handle base image and upload
          CallAwsUrl((res) => {
            handleBaseImageData(
              selectedToolset,
              handleBase64Data(res.data),
              bool,
              CNImageBlob,
              media?.originalImage,
              media?.initialCNImage
            ); //initialCNImage should be the initial else original if it does not exist
          }, updatedLink);
        }, media?.originalImage);
      } else {
        //just handle base image and upload
        CallAwsUrl((res) => {
          handleBaseImageData(selectedToolset, handleBase64Data(res.data), bool);
        }, updatedLink);
      }
    } else if (event.dataTransfer.files?.length === 1) {
      const imageUrl = handleFileChange(selectedToolset, event);
      if (imageUrl) {
        updatePreUploadState(selectedToolset, imageUrl);
        handleBaseImageData(selectedToolset, imageUrl, bool);
      }
    }
  };

  const handleOpenMaskPopup = () => {
    if (
      (editToolsetData?.isFromDesignActivated &&
        editToolsetData?.feedImageBase64 &&
        editToolsetData?.cnImageBase64 &&
        editToolsetData?.maskImageBase64) ||
      (!editToolsetData?.isFromDesignActivated &&
        editToolsetData?.feedImageBase64 &&
        editToolsetData?.maskImageBase64)
    ) {
      updateRenderToolsetData({
        showPopUpForBaseImage: true,
      });
    }
  };

  useEffect(() => {
    if (!popupVisible) {
      setVideoResetKey((prevKey) => prevKey + 1);
    }
  }, [popupVisible]);

  useEffect(() => {
    if (!editToolsetData?.uploadedBaseImage) {
      setIsVisualizedMaskUpdating(false);
    }
  }, [editToolsetData?.uploadedBaseImage]);

  const handleIsDesignActivatedToggleChange = (mode) => {
    // console.log('We are in mode:', mode);
    if (
      editToolsetData?.uploadedBaseImage &&
      editToolsetData?.feedImageBase64 &&
      editToolsetData?.cnImage
    ) {
      // console.log('All required data is present.');
      setIsVisualizedMaskUpdating(true);
      // console.log('Mask updating state set to true.');
      setTimeout(() => {
        //force a rerender
        createMaskWithCNImage(
          editToolsetData.uploadedBaseImage,
          editToolsetData.feedImageBase64.width,
          editToolsetData.feedImageBase64.height,
          editToolsetData.cnImage,
          editToolsetData.feedImageBase64.width, // Verify size correctness
          editToolsetData.feedImageBase64.height,
          mode
        )
          .then((result) => {
            // console.log("Image processing finished, updating state.");
            setIsVisualizedMaskUpdating(false);
            updateEditToolsetData({
              maskImageData: result.visualizedMaskBase64,
              isFromDesignActivated: mode,
            });
            // console.log('Mask updating state set to false.');
          })
          .catch((error) => {
            console.log("Error during image processing:", error);
            setIsVisualizedMaskUpdating(false);
          });
      }, 0);
    } else {
      // console.log('Not all data is available to process images.');
      updateEditToolsetData({
        isFromDesignActivated: mode,
      });
    }
  };

  const handleImageCount = (data) => {
    setImageCount(data);
    localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(data));
  };

  const textTooltip = t("text_to_image_content")?.split("/n");
  const designTooltip = t("image_to_image_content")?.split("/n");
  const imageTooltip = t("inpainting_content")?.split("/n");
  const enhanceTooltip = t("expand_pixels_content")?.split("/n");
  const videoTooltip = t("first_frame_tip")?.split("/n");

  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({
    left: 1700.90625,
    top: 171.578125,
  });

  const showTooltip = (no, event) => {
    if (sectionsRef.current) {
      const rect = sectionsRef.current.getBoundingClientRect();
      const left = rect.left + window.scrollX;
      const top = rect.top + window.scrollY;
      setTooltipContent(no);
      setTooltipPosition({ left, top });
    }
  };

  const hideTooltip = () => {
    setTooltipContent(0);
  };

  const handleActiveSelection = (e) => {
    const value = e;
    if (value === selection) return;
    if (checkIfSelectionIsDisabled()) return;
    handleIsDesignActivatedToggleChange(value == 2);
    updateEditToolsetData({
      isFromDesignActivated: value == 2,
    });
    if (value === "2") {
      let fidelity = JSON.parse(
        localStorage.getItem(`image-fidelity_${lastPart}`)
      );
      if (fidelity) {
        fidelity.id = 1;
        fidelity.valid = false;
        setImageFidelity(false);
        localStorage.setItem(
          `image-fidelity_${lastPart}`,
          JSON.stringify(fidelity)
        );
      }
    }
    setSelection(value);
    localStorage.setItem(`selection_${lastPart}`, value);
    if (value !== "3") {
      const check = {
        isDesign: false,
      };
      setTemplateDisable(false);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    } else {
      const check = {
        isDesign: true,
      };
      setTemplateDisable(true);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    }
  };

  const checkIfSelectionIsDisabled = () => {
    if (
      (editToolsetData?.maskImageData &&
        (!editToolsetData?.maskImageBase64 ||
          !editToolsetData?.feedImageBase64)) ||
      (editToolsetData?.cnImage &&
        (!editToolsetData?.cnImageBase64 ||
          !editToolsetData?.feedImageBase64)) ||
      isVisualizedMaskUpdating
    )
      return true;
    else return false;
  };

  const handleEnhanceSelection = (e) => {
    const value = e;
    if (value === localEnhanceOption) return;
    setEnhanceOption(value);
    localStorage.setItem(`enhance-option_${lastPart}`, value);
    if (value === "1") {
      const check = {
        isDesign: false,
      };
      setTemplateDisable(false);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else {
      const check = {
        isDesign: true,
      };
      setTemplateDisable(true);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    }
  };

  const handleImageType = (id) => {
    if (id === 1) {
      let data = {
        id: id,
        name: t("control_line"),
      };
      setImageType(id);
      localStorage.setItem(`imageTypeData_${lastPart}`, JSON.stringify(data));
    } else if (id === 2) {
      let data = {
        id: id,
        name: t("control_depth"),
      };
      setImageType(id);
      localStorage.setItem(`imageTypeData_${lastPart}`, JSON.stringify(data));
    }
  };

  const handleResolutionType = (id) => {
    let check = JSON.parse(
      localStorage.getItem(`image-resolution_${lastPart}`)
    );
    if (check) {
      check.id = id;
      setResolutionType(id);
      localStorage.setItem(
        `image-resolution_${lastPart}`,
        JSON.stringify(check)
      );
      if (
        id === 2 &&
        (imageCountLocal === "2" ||
          imageCountLocal === "3" ||
          imageCountLocal === "4")
      ) {
        setImageCount(1);
        localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(1));
      }
    } else {
      check.id = 1;
      setResolutionType(1);
      localStorage.setItem(
        `image-resolution_${lastPart}`,
        JSON.stringify(check)
      );
      if (
        id === 2 &&
        (imageCountLocal === "2" ||
          imageCountLocal === "3" ||
          imageCountLocal === "4")
      ) {
        setImageCount(1);
        localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(1));
      }
    }
  };

  const handleUpscaleType = (id) => {
    if (id === 1) {
      let data = {
        id: id,
        name: t("upscale_basic"),
      };
      setUpscaleType(id);
      localStorage.setItem(`upscaleTypeData_${lastPart}`, JSON.stringify(data));
    } else if (id === 2) {
      let data = {
        id: id,
        name: t("upscale_creative"),
      };
      setUpscaleType(id);
      localStorage.setItem(`upscaleTypeData_${lastPart}`, JSON.stringify(data));
    }
  };

  const handleDesignPreservematerial = () => {
    let preserveLocal = JSON.parse(
      localStorage.getItem(`preserve_${lastPart}`)
    );
    if (preserveLocal) {
      setPreserve(!preserveLocal?.valid ?? !preserve);
      preserveLocal.valid = !preserveLocal?.valid ?? !preserve;
      handlePreserveMaterial(1);
      localStorage.setItem(
        `preserve_${lastPart}`,
        JSON.stringify(preserveLocal)
      );
    }
  };

  const handleImageResolution = () => {
    let resolution = JSON.parse(
      localStorage.getItem(`image-resolution_${lastPart}`)
    );
    if (resolution) {
      if (imageResolution === true) {
        resolution.id = 1;
      }
      setImageResolution(!resolution?.valid ?? !imageResolution);
      resolution.valid = !resolution?.valid ?? !imageResolution;
      localStorage.setItem(
        `image-resolution_${lastPart}`,
        JSON.stringify(resolution)
      );
    }
  };

  const handleImageFidelity = () => {
    let fidelity = JSON.parse(
      localStorage.getItem(`image-fidelity_${lastPart}`)
    );
    if (fidelity) {
      if (imageFidelity === true) {
        fidelity.id = 1;
      } else {
        fidelity.id = 5;
      }
      setImageFidelity(!fidelity?.valid ?? !imageFidelity);
      fidelity.valid = !fidelity?.valid ?? !imageFidelity;
      localStorage.setItem(
        `image-fidelity_${lastPart}`,
        JSON.stringify(fidelity)
      );
    }
  };

  const popuptimer = useRef(null);

  const handleMouseEnter = (id, event, toppos = -40) => {
    event.preventDefault();
    event.stopPropagation();
    const newData1 = {
      id: activeLearnBool?.id,
      boolean: false,
      position: activeLearnBool?.position,
    };
    setActiveLearnBool(newData1);

    const headingDiv = event.currentTarget.parentNode;
    const rect = headingDiv.getBoundingClientRect();
    const position = {
      left: rect.left + window.scrollX,
      top: rect.bottom + window.scrollY + toppos,
    };
    const newData = {
      id: id,
      boolean: true,
      position: position,
    };
    clearTimeout(popuptimer.current);
    setActiveLearnBool1(newData);
  };

  const handleMouseLeave = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    const headingDiv = event.currentTarget.parentNode;
    const rect = headingDiv.getBoundingClientRect();
    const position = {
      left: rect.left + window.scrollX,
      top: rect.bottom + window.scrollY,
    };
    const newData = {
      id: id,
      boolean: false,
      position: position,
    };
    popuptimer.current = setTimeout(() => {
      setActiveLearnBool1(newData);
    }, 500);
  };

  const currentValue =
    activeExtendFidelityLevel ?? enhanceLocal?.extendFidelityLevel;

  const trackColor = currentValue < 0 ? "red" : "green";

  const extendPaddingStyle =
    percentage > 0
      ? `0 ${Math.abs(percentage) / 4}%`
      : `${Math.abs(percentage) / 4}% 0`;

  const tooltipFormatter = (value) => {
    return labels[value];
  };

  const handleFocus = (id) => {
    const data = {
      id: id,
      bool: true,
    };
    setControlFocus(data);
  };

  return (
    <div className="toolset" onClick={(e) => e.stopPropagation()}>
      <div className="toolset__container">
        <div className="toolset__sections" ref={sectionsRef}>
          <div className="toolset__section">
            <div className="toolset__section__tools">
              <div className="toolset__section__tools__heading">
                <h4>{t("toolset")}</h4>
              </div>
              <Tabs
                key={activeToolsetLocal}
                selectedIndex={isActiveToolset || 0}
                onSelect={(index) => handleToolsetClick(index)}
              >
                <TabList>
                  <div className="toolset__section__tools__tabs">
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(1, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={texti} alt="text-icon" />
                        <img className="imgh" src={texth} alt="text-icon" />
                        <img className="imgd" src={textd} alt="text-icon" />
                        <span>{t("text")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 1 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl="https://airi-development.s3.cn-north-1.amazonaws.com.cn/React-Default-Images/text.gif"
                              videoResetKey={videoResetKey}
                              heading={t("text")}
                              content={textTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(2, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={designi} alt="design-icon" />
                        <img className="imgh" src={designh} alt="design-icon" />
                        <img className="imgd" src={designd} alt="design-icon" />
                        <span>{t("design")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 2 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl="https://airi-development.s3.cn-north-1.amazonaws.com.cn/React-Default-Images/design.gif"
                              videoResetKey={videoResetKey}
                              heading={t("design")}
                              content={designTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(3, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={imagei} alt="image-icon" />
                        <img className="imgh" src={imageh} alt="image-icon" />
                        <img className="imgd" src={imaged} alt="image-icon" />
                        <span>{t("image")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 3 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl="https://airi-development.s3.cn-north-1.amazonaws.com.cn/React-Default-Images/inpainting.gif"
                              videoResetKey={videoResetKey}
                              heading={t("image")}
                              content={imageTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(4, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={upscalei} alt="upscale-icon" />
                        <img className="imgh" src={upscaleh} alt="image-icon" />
                        <img className="imgd" src={upscaled} alt="image-icon" />
                        {/* <h6 className="updated">{t("beta")}</h6> */}
                        <span>{t("enhance")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 4 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl="https://airi-development.s3.cn-north-1.amazonaws.com.cn/React-Default-Images/enhance.gif"
                              videoResetKey={videoResetKey}
                              heading={t("enhance")}
                              content={enhanceTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(5, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={videoa} alt="upscale-icon" />
                        <img className="imgh" src={videoh} alt="image-icon" />
                        <img className="imgd" src={videod} alt="image-icon" />
                        <h6 className="updated">{t("beta")}</h6>
                        <span>{t("toolset_video")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 5 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl="https://airi-production.s3.cn-north-1.amazonaws.com.cn/frontend-assets/%E6%BC%94%E7%A4%BA%E6%96%87%E7%A8%BF1(1)+(1).gif"
                              videoResetKey={videoResetKey}
                              heading={t("toolset_video")}
                              content={videoTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                  </div>
                </TabList>
                <div
                  className={`toolset__section__tools__main ${
                    editToolsetData?.isFromDesignActivated === true
                      ? "scrolling"
                      : ""
                  }`}
                  key={promptLocalData?.isTemplate}
                  data-scrollable="true"
                >
                  <TabPanel>
                    <div className="toolset__section__tools__main__text">
                      <div className="toolset__section__tools__main__text__orientation">
                        <div className="toolset__section__tools__main__text__orientation__heading">
                          <h4>{t("orientation")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__text__orientation__sec">
                          <div
                            className={`toolset__section__tools__main__text__orientation__sec__sub ${
                              toolsetLocal?.imageOrientationName === "hori"
                                ? "hori"
                                : ""
                            }`}
                            onClick={() => handleOrientation("hori")}
                          >
                            <div className="toolset__section__tools__main__text__orientation__sec__sub__section">
                              <div className="toolset__section__tools__main__text__orientation__sec__sub__section__image">
                                <img src={horizontal} alt="horizontal" />
                              </div>
                              <span>{t("horizontal")}</span>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__orientation__sec__sub ${
                              toolsetLocal?.imageOrientationName === "verti"
                                ? "verti"
                                : ""
                            }`}
                            onClick={() => handleOrientation("verti")}
                          >
                            <div className="toolset__section__tools__main__text__orientation__sec__sub__section">
                              <div className="toolset__section__tools__main__text__orientation__sec__sub__section__image">
                                <img
                                  className="vertical"
                                  src={vertical}
                                  alt="vertical"
                                />
                              </div>
                              <span>{t("vertical")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__text__ratio">
                        <div className="toolset__section__tools__main__text__ratio__heading">
                          <h4>{t("image_ratio")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__text__ratio__sec">
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              toolsetLocal?.imageRatioName === "fourthree"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick("fourthree")}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>4:3</h6>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              toolsetLocal?.imageRatioName === "sixteennine"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick("sixteennine")}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>16:9</h6>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              toolsetLocal?.imageRatioName === "threetwo"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick("threetwo")}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>3:2</h6>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              toolsetLocal?.imageRatioName === "oneone"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick("oneone")}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>1:1</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__text__image-count">
                        <div className="toolset__section__tools__main__text__image-count__heading">
                          <h4>{t("image_count")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__text__image-count__sec">
                          <div
                            className={`toolset__section__tools__main__text__image-count__sec__sub ${
                              imageCountLocal === "1" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(1)}
                          >
                            <h6>1</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__image-count__sec__sub ${
                              imageCountLocal === "2" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(2)}
                          >
                            <h6>2</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__image-count__sec__sub ${
                              imageCountLocal === "3" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(3)}
                          >
                            <h6>3</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__image-count__sec__sub ${
                              imageCountLocal === "4" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(4)}
                          >
                            <h6>4</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__design">
                      <div className="toolset__section__tools__main__design__base-img">
                        <div
                          className="toolset__section__tools__main__design__base-img__heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("base_image")}</h4>
                          {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(3, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 3
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("base_image")}
                                  content={t("base_image_tooltip")}
                                  styles="260px"
                                  image={renderimage}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )}
                        </div>
                        <div
                          className={`card ${
                            controlFocus?.id === 1 && controlFocus?.bool
                              ? "focus"
                              : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, 1)}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChangeWithToolsetInput(1)}
                          />
                          <label
                            htmlFor="fileInput"
                            style={{
                              cursor:
                                designLocalBase?.uploadedBaseImageUrl &&
                                !designLocalBase?.feedDesignBase64
                                  ? "progress"
                                  : "pointer",
                            }}
                          >
                            <div
                              className="card-content"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, 1)}
                              style={{
                                justifyContent: `${
                                  designLocalBase?.uploadedBaseImageUrl
                                    ? "Center"
                                    : "FlexEnd"
                                }`,
                                padding: `${
                                  designLocalBase?.uploadedBaseImageUrl 
                                    ? "0"
                                    : "0 0 22px 0"
                                }`,
                              }}
                            >
                              {designLocalBase?.uploadedBaseImageUrl ? (
                                <>
                                  <img
                                    className="uploaded"
                                    src={designLocalBase?.uploadedBaseImageUrl
                                    }
                                    draggable="false"
                                  />
                                  {designLocalBase?.feedDesignBase64 === null ||
                                  designLocalBase?.feedDesignBase64 ===
                                    undefined ? (
                                    <>
                                      <div className="gradient-mask"></div>
                                      <div className="text-display">
                                        {t("uploading...")}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    className={`icon ${
                                      controlFocus?.id === 1 &&
                                      controlFocus?.bool
                                        ? "focus"
                                        : ""
                                    }`}
                                    src={
                                      controlFocus?.id === 1 &&
                                      controlFocus?.bool
                                        ? controlanim
                                        : baseicon
                                    }
                                    alt=""
                                  />
                                  <p>{t("design_base_image_text1")}</p>
                                  {/* <p>{t("design_base_image_text2")}</p> */}
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                        {/* )} */}
                      </div>
                      <div className="toolset__section__tools__main__design__camera camera-main">
                        {/* <div
                          className="toolset__section__tools__main__design__camera__heading camera-main-heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("control_type_new")}</h4>
                          {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(13, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 13
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("control_type_new")}
                                  content={t("Control_type_content")}
                                  styles="0px"
                                  image={controltypeimage}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )}
                        </div> */}
                        <div className="toolset__section__tools__main__design__imagetype">
                          <div className="toolset__section__tools__main__design__imagetype__main">
                            <div
                              className="toolset__section__tools__main__design__imagetype__heading"
                              key={imageTypeData}
                              onMouseEnter={(event) =>
                                handleMouseEnter(1, event)
                              }
                              onMouseLeave={(event) =>
                                handleMouseLeave(1, event)
                              }
                            >
                              <div
                                className={`toolset__section__tools__main__design__imagetype__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                  imageType === 1 ? "active" : ""
                                }`}
                                onClick={() => handleImageType(1)}
                              >
                                <h5>{t("control_line")}</h5>
                              </div>
                              <div
                                className={`toolset__section__tools__main__design__imagetype__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                  imageType === 2 ? "active" : ""
                                }`}
                                onClick={() => handleImageType(2)}
                              >
                                <h5>{t("control_depth")}</h5>
                              </div>
                              {!responsiveData && (
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool1?.boolean === true &&
                                      activeLearnBool1?.id === 1
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("hover_control_heading")}
                                    content={t("hover_control_content")}
                                    styles="0px"
                                    image={controlTypeLevel}
                                    position={activeLearnBool1?.position}
                                  />
                                </Portal>
                              )}
                            </div>
                            <div className="toolset__section__tools__main__design__imagetype__slider">
                              <Slider
                                // key={activeFidelityLevel}
                                value={
                                  activeFidelityLevel ??
                                  toolsetLocal?.renderFidelityLevel
                                }
                                step={1}
                                min={1}
                                max={9}
                                onChange={handleSliderChange}
                              />
                              <span>
                                {activeFidelityLevel ??
                                  toolsetLocal?.renderFidelityLevel}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__design__fidelity">
                        <div
                          className="toolset__section__tools__main__design__fidelity__heading"
                          style={{ cursor: "pointer" }}
                          onClick={handleDesignPreservematerial}
                        >
                          <div
                            className="toolset__section__tools__main__design__fidelity__heading__left"
                            style={{ position: "relative" }}
                          >
                            <h4>{t("preserve_material_new")}</h4>
                            {!responsiveData && (
                              <div
                                className="tooltip-top"
                                onClick={(event) =>
                                  handleActiveLearn(31, event)
                                }
                              >
                                <img src={intro} alt="intro" />
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 31
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("preserve_material_new")}
                                    content={t("fidelity_tooltip1")}
                                    styles="80px"
                                    image={preserveimage}
                                    position={activeLearnBool?.position}
                                  />
                                </Portal>
                              </div>
                            )}
                          </div>
                          <div className="toolset__section__tools__main__design__fidelity__heading__right">
                            <Switch
                              key={preserveLocal?.valid ?? preserve}
                              onChange={handleDesignPreservematerial}
                              checked={
                                preserveLocal?.valid ?? preserve ? true : false
                              }
                              height={responsiveData ? 14 : 16}
                              width={responsiveData ? 28 : 32}
                              handleDiameter={12}
                              offColor="#A8ADAF"
                              onColor="#000"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              className={`${
                                responsiveData
                                  ? preserveLocal?.valid ?? preserve
                                    ? "small-on"
                                    : "small-off"
                                  : ""
                              }react-switch-Undragable`}
                            />
                          </div>
                        </div>
                        <div className="toolset__section__tools__main__design__fidelity__sec">
                          {(preserveLocal?.valid ?? preserve) && (
                            <div className="toolset__section__tools__main__design__fidelity__sec__sub">
                              <Slider
                                // key={activePreserveMaterial}
                                value={
                                  activePreserveMaterial ??
                                  toolsetLocal?.preserveMaterial
                                }
                                step={1}
                                min={1}
                                max={9}
                                onChange={handlePreserveMaterial}
                              />
                              <span>
                                {activePreserveMaterial ??
                                  toolsetLocal?.preserveMaterial}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__design__resolution">
                        <div
                          className="toolset__section__tools__main__design__resolution__heading"
                          style={{ cursor: "pointer" }}
                          onClick={handleImageResolution}
                        >
                          <div
                            className="toolset__section__tools__main__design__resolution__heading__left"
                            style={{ position: "relative" }}
                          >
                            <h4>{t("image_resolution")}</h4>
                            {!responsiveData && (
                              <div
                                className="tooltip-top"
                                onClick={(event) =>
                                  handleActiveLearn(32, event)
                                }
                              >
                                <img src={intro} alt="intro" />
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 32
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("image_resolution")}
                                    content={t("image_resolution_tooltip")}
                                    styles={`${
                                      preserveLocal?.valid ?? preserve
                                        ? languageActive === "ch"
                                          ? "80px"
                                          : "100px"
                                        : languageActive === "ch"
                                        ? "120px"
                                        : "140px"
                                    }`}
                                    image={imageresolutionimage}
                                    position={activeLearnBool?.position}
                                  />
                                </Portal>
                              </div>
                            )}
                            <span>{t("beta")}</span>
                          </div>
                          <div className="toolset__section__tools__main__design__fidelity__heading__right">
                            <Switch
                              key={resolutionLocal?.valid ?? imageResolution}
                              onChange={handleImageResolution}
                              checked={
                                resolutionLocal?.valid ?? imageResolution
                                  ? true
                                  : false
                              }
                              height={responsiveData ? 14 : 16}
                              width={responsiveData ? 28 : 32}
                              handleDiameter={12}
                              offColor="#A8ADAF"
                              onColor="#000"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              className={`${
                                responsiveData
                                  ? resolutionLocal?.valid ?? imageResolution
                                    ? "small-on"
                                    : "small-off"
                                  : ""
                              }react-switch-Undragable`}
                            />
                          </div>
                        </div>
                        {(resolutionLocal?.valid ?? imageResolution) && (
                          <div className="toolset__section__tools__main__design__resolution__section">
                            <div
                              className="toolset__section__tools__main__design__resolution__section__heading"
                              key={resolutionLocal}
                            >
                              <div
                                className={`toolset__section__tools__main__design__resolution__section__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                  resolutionType === 1 ? "active" : ""
                                }`}
                                onClick={() => handleResolutionType(1)}
                              >
                                <h5>2.25 {t("megapixels")}</h5>
                              </div>
                              <div
                                className={`toolset__section__tools__main__design__resolution__section__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                  resolutionType === 2 ? "active" : ""
                                }`}
                                onClick={() => handleResolutionType(2)}
                              >
                                <h5>4 {t("megapixels")}</h5>
                              </div>
                            </div>
                            {/* <h6>{t("image_resolution_tip")}</h6> */}
                          </div>
                        )}
                      </div>
                      <div className="toolset__section__tools__main__design__image-count">
                        <div className="toolset__section__tools__main__design__image-count__heading">
                          <h4>{t("image_count")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__design__image-count__sec">
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              imageCountLocal === "1" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(1)}
                          >
                            <h6>1</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              imageCountLocal === "2" ? "active" : ""
                            } ${resolutionType === 2 ? "disable" : ""}`}
                            onClick={
                              resolutionType === 2
                                ? null
                                : () => handleImageCount(2)
                            }
                          >
                            <h6>2</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              imageCountLocal === "3" ? "active" : ""
                            } ${resolutionType === 2 ? "disable" : ""}`}
                            onClick={
                              resolutionType === 2
                                ? null
                                : () => handleImageCount(3)
                            }
                          >
                            <h6>3</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              imageCountLocal === "4" ? "active" : ""
                            } ${resolutionType === 2 ? "disable" : ""}`}
                            onClick={
                              resolutionType === 2
                                ? null
                                : () => handleImageCount(4)
                            }
                          >
                            <h6>4</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__imagec">
                      {!editToolsetData?.uploadedBaseImage && (
                        <div className="toolset__section__tools__main__imagec__base-img">
                          <div
                            className="toolset__section__tools__main__imagec__base-img__heading"
                            style={{ position: "relative" }}
                          >
                            <h4>{t("base_image")}</h4>
                            {!responsiveData && (
                              <div
                                className="tooltip-top"
                                onClick={(event) => handleActiveLearn(7, event)}
                              >
                                <img src={intro} alt="intro" />
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 7
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("base_image")}
                                    content={t("base_image_tooltip_edit")}
                                    styles="260px"
                                    image={renderimage}
                                    position={activeLearnBool?.position}
                                  />
                                </Portal>
                              </div>
                            )}
                          </div>
                          <div
                            className={`card ${
                              controlFocus?.id === 2 && controlFocus?.bool
                                ? "focus"
                                : ""
                            }`}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, 2)}
                          >
                            <input
                              key={inputKey}
                              type="file"
                              accept=".jpeg, .jpg, .png, .webp"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={handleFileChangeWithToolsetInput(2)}
                            />
                            <label
                              htmlFor="fileInput"
                              style={{
                                cursor:
                                  editToolsetData?.uploadedBaseImage &&
                                  !editToolsetData?.feedImageBase64
                                    ? "progress"
                                    : "pointer",
                              }}
                            >
                              <div
                                className="card-content"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, 2)}
                                style={{
                                  justifyContent: `${
                                    editToolsetData?.uploadedBaseImage
                                      ? "Center"
                                      : "FlexEnd"
                                  }`,
                                }}
                              >
                                {editToolsetData?.uploadedBaseImage ? (
                                  <>
                                    <img
                                      className="uploaded"
                                      src={editToolsetData?.uploadedBaseImage}
                                      alt=""
                                      draggable="false"
                                    />
                                    {editToolsetData?.feedImageBase64 ===
                                      null ||
                                    editToolsetData?.feedImageBase64 ===
                                      undefined ? (
                                      <>
                                        <div className="gradient-mask"></div>
                                        <div className="text-display">
                                          {t("uploading...")}
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      className={`icon ${
                                        controlFocus?.id === 2 &&
                                        controlFocus?.bool
                                          ? "focus"
                                          : ""
                                      }`}
                                      src={
                                        controlFocus?.id === 2 &&
                                        controlFocus?.bool
                                          ? controlanim
                                          : baseicon
                                      }
                                      alt=""
                                    />
                                    <p>{t("image_base_image_text1")}</p>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>
                          {/* )} */}
                        </div>
                      )}
                      {editToolsetData?.uploadedBaseImage && (
                        <div className="toolset__section__tools__main__imagec__mask">
                          <div
                            className="toolset__section__tools__main__imagec__mask__heading"
                            style={{ position: "relative" }}
                          >
                            <div className="toolset__section__tools__main__imagec__mask__heading__leftSide">
                              <h4>{t("base_image")}</h4>
                              {!responsiveData && (
                                <div
                                  className="tooltip-top"
                                  onClick={(event) =>
                                    handleActiveLearn(9, event)
                                  }
                                >
                                  <img src={intro} alt="intro" />
                                  <Portal
                                    style={{
                                      display: `${
                                        activeLearnBool?.boolean === true &&
                                        activeLearnBool?.id === 9
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    <LearnPopups
                                      heading={t("mask")}
                                      content={t("mask_tooltip")}
                                      styles="260px"
                                      image={maskimage}
                                      position={activeLearnBool?.position}
                                    />
                                  </Portal>
                                </div>
                              )}
                            </div>
                            {editToolsetData?.isFromDesignActivated &&
                            !editToolsetData?.cnImage ? (
                              <button
                                onClick={() => handleFocus(4)}
                                style={{
                                  cursor: "default",
                                  background: "#fff",
                                  color: "#263238",
                                  border: "1px solid #ededed",
                                }}
                              >
                                <h4
                                  style={{
                                    color: "#263238",
                                  }}
                                >
                                  {t("edit_mask")}
                                </h4>
                              </button>
                            ) : (
                              <button
                                onClick={handleOpenMaskPopup}
                                style={{
                                  cursor:
                                    !editToolsetData?.uploadedBaseImage ||
                                    (editToolsetData?.isFromDesignActivated &&
                                      !editToolsetData?.cnImage)
                                      ? "default"
                                      : !editToolsetData?.feedImageBase64
                                      ? "progress"
                                      : !editToolsetData?.maskImageBase64
                                      ? "progress"
                                      : "pointer",
                                }}
                                disabled={
                                  (editToolsetData?.maskImageData &&
                                    (!editToolsetData?.maskImageBase64 ||
                                      !editToolsetData?.feedImageBase64)) ||
                                  (editToolsetData?.cnImage &&
                                    (!editToolsetData?.cnImageBase64 ||
                                      !editToolsetData?.feedImageBase64)) ||
                                  (editToolsetData?.isFromDesignActivated &&
                                    !editToolsetData?.cnImage)
                                }
                              >
                                <h4>{t("edit_mask")}</h4>
                              </button>
                            )}
                          </div>
                          <div className="toolset__section__tools__main__imagec__mask__sec">
                            <div className="toolset__section__tools__main__imagec__mask__sec__image">
                              <div
                                className="card"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                <input
                                  key={inputKey}
                                  type="file"
                                  accept=".jpeg, .jpg, .png, .webp"
                                  id="fileInput"
                                  style={{ display: "none" }}
                                  onChange={handleFileChangeWithToolsetInput(2)}
                                  disabled={checkIfSelectionIsDisabled()}
                                />
                                <label
                                  // onClick={handleOpenMaskPopup}
                                  htmlFor="fileInput"
                                  style={{
                                    cursor: !editToolsetData?.uploadedBaseImage
                                      ? "not-allowed"
                                      : !editToolsetData?.feedImageBase64
                                      ? "progress"
                                      : !editToolsetData?.maskImageBase64
                                      ? "progress"
                                      : editToolsetData?.isFromDesignActivated &&
                                        !editToolsetData?.cnImageBase64
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                >
                                  <div
                                    className="card-content"
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    style={{
                                      justifyContent: `${
                                        editToolsetData?.uploadedBaseImage
                                          ? "Center"
                                          : "FlexEnd"
                                      }`,
                                    }}
                                  >
                                    {editToolsetData?.maskImageData ? (
                                      <>
                                        <img
                                          className="uploaded"
                                          src={editToolsetData?.maskImageData}
                                          alt=""
                                          draggable="false"
                                        />
                                        {editToolsetData?.maskImageBase64 ===
                                          null || undefined ? (
                                          <>
                                            <div className="gradient-mask"></div>
                                            <div className="text-display">
                                              {t("uploading...")}
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <img
                                        className="icon"
                                        src={baseicon}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selection === "2" && (
                        <div className="toolset__section__tools__main__imagec__selection__main selection">
                          {/* <div className="toolset__section__tools__main__imagec__fidelity">
                            <div className="toolset__section__tools__main__imagec__fidelity__heading">
                              <div
                                className="toolset__section__tools__main__imagec__fidelity__heading__left"
                                style={{ position: "relative" }}
                              >
                                <h4>{t("creativity")}</h4>
                                {!responsiveData && (
                                  <div
                                    className="tooltip-top"
                                    onClick={(event) =>
                                      handleActiveLearn(26, event)
                                    }
                                  >
                                    <img src={intro} alt="intro" />
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 26
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      <LearnPopups
                                        heading={t("creativity")}
                                        content={t("modification_tooltip1")}
                                        styles="120px"
                                        image={modificationLevelImage}
                                        position={activeLearnBool?.position}
                                      />
                                    </Portal>
                                  </div>
                                )}
                              </div>
                              <div className="toolset__section__tools__main__imagec__fidelity__heading__right">
                                <Switch
                                  key={
                                    imagefidelityLocal?.valid ?? imageFidelity
                                  }
                                  onChange={handleImageFidelity}
                                  checked={
                                    imagefidelityLocal?.valid ?? imageFidelity
                                      ? true
                                      : false
                                  }
                                  height={responsiveData ? 14 : 16}
                                  width={responsiveData ? 28 : 32}
                                  handleDiameter={12}
                                  offColor="#A8ADAF"
                                  onColor="#000"
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  className={`${
                                    responsiveData
                                      ? imagefidelityLocal?.valid ??
                                        imageFidelity
                                        ? "small-on"
                                        : "small-off"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                            {(imagefidelityLocal?.valid ?? imageFidelity) && (
                              <div className="toolset__section__tools__main__imagec__fidelity__sec">
                                <div className="toolset__section__tools__main__imagec__fidelity__sec__sub">
                                  <Slider
                                    // key={activeImageFidelityLevel}
                                    value={
                                      activeImageFidelityLevel ??
                                      imagefidelityLocal?.id
                                    }
                                    step={1}
                                    min={1}
                                    max={9}
                                    onChange={handleImageSliderChange}
                                  />
                                  <span>
                                    {activeImageFidelityLevel ??
                                      imagefidelityLocal?.id}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div> */}
                        </div>
                      )}
                      <div className="toolset__section__tools__main__imagec__selection">
                        <div className="toolset__section__tools__main__imagec__selection__sub">
                          <button
                            className={`toolset__section__tools__main__imagec__selection__sub__sec ${
                              selection === "1" ? "active" : ""
                            }`}
                            onMouseEnter={(event) =>
                              handleMouseEnter(31, event, 100)
                            }
                            onMouseLeave={(event) =>
                              handleMouseLeave(31, event, 100)
                            }
                            onClick={() => handleActiveSelection("1")}
                            disabled={checkIfSelectionIsDisabled()}
                            style={{
                              cursor: checkIfSelectionIsDisabled()
                                ? "progress"
                                : "pointer",
                            }}
                          >
                            {!responsiveData && (
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool1?.boolean === true &&
                                    activeLearnBool1?.id === 31
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("hover_inpaint_heading")}
                                  content={t("hover_inpaint_content")}
                                  styles="0px"
                                  image={inpaintimage}
                                  position={activeLearnBool1?.position}
                                />
                              </Portal>
                            )}
                            <img
                              className="imga"
                              src={inpainti}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={inpainth}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={inpaintd}
                              alt="image-icon"
                            />
                            {selection === "1" && <h5>{t("inpaint")}</h5>}
                          </button>
                          <button
                            className={`toolset__section__tools__main__imagec__selection__sub__sec  ${
                              selection === "2" ? "active" : ""
                            }`}
                            onMouseEnter={(event) =>
                              handleMouseEnter(32, event, 100)
                            }
                            onMouseLeave={(event) =>
                              handleMouseLeave(32, event, 100)
                            }
                            onClick={() => handleActiveSelection("2")}
                            disabled={checkIfSelectionIsDisabled()}
                            style={{
                              cursor: checkIfSelectionIsDisabled()
                                ? "progress"
                                : "pointer",
                            }}
                          >
                            {!responsiveData && (
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool1?.boolean === true &&
                                    activeLearnBool1?.id === 32
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("hover_controlinpaint_heading")}
                                  content={t("hover_controlinpaint_content")}
                                  styles="0px"
                                  image={controlinpaintimage}
                                  position={activeLearnBool1?.position}
                                  hideLinks={true}
                                />
                              </Portal>
                            )}
                            <img
                              className="imga"
                              src={shapei}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={shapeh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={shaped}
                              alt="image-icon"
                            />
                            {selection === "2" && <h5>{t("shape")}</h5>}
                          </button>
                          <button
                            className={`toolset__section__tools__main__imagec__selection__sub__sec ${
                              selection === "3" ? "active" : ""
                            }`}
                            onMouseEnter={(event) =>
                              handleMouseEnter(33, event, 100)
                            }
                            onMouseLeave={(event) =>
                              handleMouseLeave(33, event, 100)
                            }
                            onClick={() => handleActiveSelection("3")}
                            disabled={checkIfSelectionIsDisabled()}
                            style={{
                              cursor: checkIfSelectionIsDisabled()
                                ? "progress"
                                : "pointer",
                            }}
                          >
                            {!responsiveData && (
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool1?.boolean === true &&
                                    activeLearnBool1?.id === 33
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("hover_eraser_heading")}
                                  content={t("hover_eraser_content")}
                                  styles="0px"
                                  image={eraserimage}
                                  position={activeLearnBool1?.position}
                                />
                              </Portal>
                            )}
                            <img
                              className="imga"
                              src={eraseri}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={eraserh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={eraserd}
                              alt="image-icon"
                            />
                            {selection === "3" && <h5>{t("eraser")}</h5>}
                          </button>
                        </div>
                      </div>
                      {selection === "2" && (
                        <div className="toolset__section__tools__main__imagec__selection__main">
                          {/* <div className="toolset__section__tools__main__imagec__CNSwitch">
                            <div
                              className="toolset__section__tools__main__imagec__CNSwitch__heading"
                              style={{ position: "relative" }}
                            >
                              <h4>{t("shape_control_image")}</h4>
                              {!responsiveData && (
                                <div
                                  className="tooltip-top"
                                  onClick={(event) =>
                                    handleActiveLearn(10, event)
                                  }
                                >
                                  <img src={intro} alt="intro" />
                                  <Portal
                                    style={{
                                      display: `${
                                        activeLearnBool?.boolean === true &&
                                        activeLearnBool?.id === 10
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    <LearnPopups
                                      heading={t("controlnet_inpainting")}
                                      content={t(
                                        "controlnet_inpainting_tooltip"
                                      )}
                                      styles={
                                        languageActive === "ch"
                                          ? "-30px"
                                          : "60px"
                                      }
                                      image={controlimage}
                                      position={activeLearnBool?.position}
                                    />
                                  </Portal>
                                </div>
                              )}
                            </div>
                          </div> */}
                          <div className="toolset__section__tools__main__CNimageSec">
                            <div className="toolset__section__tools__main__CNimageSec__base-img">
                              <div
                                className="toolset__section__tools__main__CNimageSec__base-img__heading"
                                style={{ position: "relative" }}
                                key={controlFocus}
                              >
                                <div
                                  className={`card ${
                                    controlFocus?.id === 4 && controlFocus?.bool
                                      ? "focus"
                                      : ""
                                  }`}
                                  onDragOver={handleDragOver}
                                  onDrop={(e) => handleDrop(e, 10)}
                                >
                                  <input
                                    key={inputKey}
                                    type="file"
                                    accept=".jpeg, .jpg, .png, .webp"
                                    id="fileInputCNImage"
                                    style={{ display: "none" }}
                                    onChange={handleFileChangeWithToolsetInput(
                                      10
                                    )}
                                    disabled={!editToolsetData?.feedImageBase64}
                                  />
                                  <label
                                    htmlFor="fileInputCNImage"
                                    style={{
                                      cursor:
                                        !editToolsetData?.uploadedBaseImage
                                          ? "not-allowed"
                                          : (editToolsetData?.cnImage &&
                                              !editToolsetData?.cnImageBase64) ||
                                            !editToolsetData?.feedImageBase64
                                          ? "progress"
                                          : "pointer",
                                    }}
                                  >
                                    <div
                                      className="card-content"
                                      onDragOver={handleDragOver}
                                      onDrop={(e) => handleDrop(e, 10)}
                                      style={{
                                        justifyContent: `${
                                          editToolsetData?.cnImage
                                            ? "Center"
                                            : "FlexEnd"
                                        }`,
                                      }}
                                    >
                                      {editToolsetData?.cnImage ? (
                                        <>
                                          <img
                                            className="uploaded"
                                            src={editToolsetData?.cnImage}
                                            draggable="false"
                                          />
                                          {editToolsetData?.cnImageBase64 ===
                                            null ||
                                          editToolsetData?.cnImageBase64 ===
                                            undefined ||
                                          !editToolsetData?.feedImageBase64 ? (
                                            <>
                                              <div className="gradient-mask"></div>
                                              <div className="text-display">
                                                {t("uploading...")}
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className={`icon ${
                                              controlFocus?.id === 4 &&
                                              controlFocus?.bool
                                                ? "focus"
                                                : ""
                                            }`}
                                            src={
                                              controlFocus?.id === 4 &&
                                              controlFocus?.bool
                                                ? controlanim
                                                : baseicon
                                            }
                                            alt=""
                                          />
                                          <p>{t("image_cn_image_text1")}</p>
                                        </>
                                      )}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="toolset__section__tools__main__imagec__base-img__camera">
                            {/* <div
                              className="toolset__section__tools__main__imagec__base-img__camera__heading"
                              style={{ position: "relative" }}
                            >
                              <h4>{t("control_type_new")}</h4>
                              {!responsiveData && (
                                <div
                                  className="tooltip-top"
                                  onClick={(event) =>
                                    handleActiveLearn(12, event)
                                  }
                                >
                                  <img src={intro} alt="intro" />
                                  <Portal
                                    style={{
                                      display: `${
                                        activeLearnBool?.boolean === true &&
                                        activeLearnBool?.id === 12
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    <LearnPopups
                                      heading={t("control_type_new")}
                                      content={t("imageType_tooltip_design")}
                                      styles="0px"
                                      image={controltypeimage}
                                      position={activeLearnBool?.position}
                                    />
                                  </Portal>
                                </div>
                              )}
                            </div> */}
                            <div className="toolset__section__tools__main__imagec__imagetype">
                              <div className="toolset__section__tools__main__imagec__imagetype__main">
                                <div
                                  className="toolset__section__tools__main__imagec__imagetype__heading"
                                  key={imageTypeData}
                                  onMouseEnter={(event) =>
                                    handleMouseEnter(1, event)
                                  }
                                  onMouseLeave={(event) =>
                                    handleMouseLeave(1, event)
                                  }
                                >
                                  <div
                                    className={`toolset__section__tools__main__imagec__imagetype__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                      imageType === 1 ? "active" : ""
                                    }`}
                                    onClick={() => handleImageType(1)}
                                  >
                                    <h5>{t("control_line")}</h5>
                                  </div>
                                  <div
                                    className={`toolset__section__tools__main__imagec__imagetype__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                      imageType === 2 ? "active" : ""
                                    }`}
                                    onClick={() => handleImageType(2)}
                                  >
                                    <h5>{t("control_depth")}</h5>
                                  </div>
                                  <Portal
                                    style={{
                                      display: `${
                                        activeLearnBool1?.boolean === true &&
                                        activeLearnBool1?.id === 1
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    <LearnPopups
                                      heading={t("hover_control_heading")}
                                      content={t("hover_control_content")}
                                      styles="0px"
                                      image={controlTypeLevel}
                                      position={activeLearnBool1?.position}
                                    />
                                  </Portal>
                                </div>
                                <div className="toolset__section__tools__main__imagec__imagetype__slider">
                                  <Slider
                                    // key={activeMaskFidelityLevel}
                                    value={
                                      activeMaskFidelityLevel ??
                                      toolsetLocal?.maskFidelityLevel
                                    }
                                    step={1}
                                    min={1}
                                    max={9}
                                    onChange={handleMaskSliderChange}
                                  />
                                  <span>
                                    {activeMaskFidelityLevel ??
                                      toolsetLocal?.maskFidelityLevel}
                                  </span>
                                </div>
                              </div>
                              <div className="toolset__section__tools__main__imagec__fidelity">
                          <div className="toolset__section__tools__main__imagec__fidelity__heading">
                            <div
                              className="toolset__section__tools__main__imagec__fidelity__heading__left"
                              style={{ position: "relative" }}
                            >
                              <h4>{t("preserve_material")}</h4>
                              {!responsiveData && (
                                <div
                                  className="tooltip-top"
                                  onClick={(event) =>
                                    handleActiveLearn(26, event)
                                  }
                                >
                                  <img src={intro} alt="intro" />
                                  <Portal
                                    style={{
                                      display: `${
                                        activeLearnBool?.boolean === true &&
                                        activeLearnBool?.id === 26
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    <LearnPopups
                                      heading={t("preserve_material")}
                                      content={t("modification_tooltip1")}
                                      styles={languageActive === "ch" ? "100px" : "100px"}
                                      image={modificationLevelImage}
                                      position={activeLearnBool?.position}
                                    />
                                  </Portal>
                                </div>
                              )}
                            </div>
                            <div className="toolset__section__tools__main__imagec__fidelity__heading__right">
                              <Switch
                                key={
                                  imagefidelityLocal?.valid ?? imageFidelity
                                }
                                onChange={handleImageFidelity}
                                checked={
                                  imagefidelityLocal?.valid ?? imageFidelity
                                    ? true
                                    : false
                                }
                                height={responsiveData ? 14 : 16}
                                width={responsiveData ? 28 : 32}
                                handleDiameter={12}
                                offColor="#A8ADAF"
                                onColor="#000"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`${
                                  responsiveData
                                    ? imagefidelityLocal?.valid ??
                                      imageFidelity
                                      ? "small-on"
                                      : "small-off"
                                    : ""
                                }`}
                              />
                            </div>
                          </div>
                          {(imagefidelityLocal?.valid ?? imageFidelity) && (
                            <div className="toolset__section__tools__main__imagec__fidelity__sec">
                              <div className="toolset__section__tools__main__imagec__fidelity__sec__sub">
                                <Slider
                                  // key={activeImageFidelityLevel}
                                  value={
                                    activeImageFidelityLevel ??
                                    imagefidelityLocal?.id
                                  }
                                  step={1}
                                  min={1}
                                  max={9}
                                  onChange={handleImageSliderChange}
                                />
                                <span>
                                  {activeImageFidelityLevel ??
                                    imagefidelityLocal?.id}
                                </span>
                              </div>
                            </div>
                          )}
                            </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="toolset__section__tools__main__imagec__image-count">
                        <div className="toolset__section__tools__main__imagec__image-count__heading">
                          <h4>{t("image_count")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__imagec__image-count__sec">
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              imageCountLocal === "1" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(1)}
                          >
                            <h6>1</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              imageCountLocal === "2" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(2)}
                          >
                            <h6>2</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              imageCountLocal === "3" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(3)}
                          >
                            <h6>3</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              imageCountLocal === "4" ? "active" : ""
                            }`}
                            onClick={() => handleImageCount(4)}
                          >
                            <h6>4</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__upscale">
                      <div className="toolset__section__tools__main__upscale__base-img">
                        <div
                          className="toolset__section__tools__main__upscale__base-img__heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("base_image")}</h4>
                          {/* {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(11, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 11
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("low_definition_image")}
                                  content={t("upscale_tooltip")}
                                  styles="260px"
                                  image={upscaleimage}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )} */}
                        </div>
                        <div
                          className={`card ${
                            controlFocus?.id === 3 && controlFocus?.bool
                              ? "focus"
                              : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, 3)}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChangeWithToolsetInput(3)}
                          />
                          <label
                            htmlFor="fileInput"
                            style={{
                              cursor:
                                upscaleLocalBase?.isUpScaleURLImgUrl &&
                                !upscaleLocalBase?.feedUpBase64
                                  ? "progress"
                                  : "pointer",
                            }}
                          >
                            <div
                              className="card-content"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, 3)}
                              style={{
                                justifyContent: `${
                                  upscaleLocalBase?.isUpScaleURLImgUrl
                                    ? "Center"
                                    : "FlexEnd"
                                }`,
                              }}
                            >
                              {upscaleLocalBase?.isUpScaleURLImgUrl ? (
                                <>
                                  {localEnhanceOption === "1" ? (
                                    <div
                                      className="image-back"
                                      style={{
                                        padding: extendPaddingStyle,
                                      }}
                                    >
                                      <img
                                        ref={imgRef}
                                        className="uploaded"
                                        src={
                                          upscaleLocalBase?.isUpScaleURLImgUrl
                                        }
                                        draggable="false"
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      style={{ objectFit: "contain" }}
                                      ref={imgRef}
                                      className="uploaded"
                                      src={upscaleLocalBase?.isUpScaleURLImgUrl}
                                      draggable="false"
                                    />
                                  )}
                                  {upscaleLocalBase?.feedUpBase64 === null ||
                                  undefined ? (
                                    <>
                                      <div className="gradient-mask"></div>
                                      <div className="text-display">
                                        {t("uploading...")}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <img
                                  className={`icon ${
                                    controlFocus?.id === 3 && controlFocus?.bool
                                      ? "focus"
                                      : ""
                                  }`}
                                  src={
                                    controlFocus?.id === 3 && controlFocus?.bool
                                      ? controlanim
                                      : baseicon
                                  }
                                  alt=""
                                />
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      {/* <div className="toolset__section__tools__main__upscale__ratio">
                        <div className="toolset__section__tools__main__upscale__ratio__heading">
                          <h4>{t("upscale_ratio")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__upscale__ratio__sec">
                          <div
                            className="toolset__section__tools__main__upscale__ratio__sec__sub"
                            style={{
                              cursor: "default",
                              background: "white",
                              border: "1px solid #EDEDED",
                            }}
                          >
                            <span>4x</span>
                          </div>
                        </div>
                      </div> */}
                      <div className="toolset__section__tools__main__upscale__selection">
                        <div className="toolset__section__tools__main__upscale__selection__sub">
                          <button
                            className={`toolset__section__tools__main__upscale__selection__sub__sec ${
                              enhanceOption === "1" ? "active" : ""
                            }`}
                            onClick={() => handleEnhanceSelection("1")}
                            // disabled={checkIfSelectionIsDisabled()}
                            // style={{
                            //   cursor: checkIfSelectionIsDisabled()
                            //     ? "progress"
                            //     : "pointer",
                            // }}
                            onMouseEnter={(event) => handleMouseEnter(2, event)}
                            onMouseLeave={(event) => handleMouseLeave(2, event)}
                          >
                            <img
                              className="imga"
                              src={extendi}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={extendh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={extendd}
                              alt="image-icon"
                            />
                            {enhanceOption === "1" && (
                              <h5>{t("enhance_option1")}</h5>
                            )}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool1?.boolean === true &&
                                  activeLearnBool1?.id === 2
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <LearnPopups
                                heading={t("enhance_option1")}
                                content={t("enhance_option1_content")}
                                styles="0px"
                                image={extendinfo}
                                position={activeLearnBool1?.position}
                              />
                            </Portal>
                          </button>
                          <button
                            className={`toolset__section__tools__main__upscale__selection__sub__sec  ${
                              enhanceOption === "2" ? "active" : ""
                            }`}
                            onClick={() => handleEnhanceSelection("2")}
                            // disabled={checkIfSelectionIsDisabled()}
                            // style={{
                            //   cursor: checkIfSelectionIsDisabled()
                            //     ? "progress"
                            //     : "pointer",
                            // }}
                            onMouseEnter={(event) => handleMouseEnter(3, event)}
                            onMouseLeave={(event) => handleMouseLeave(3, event)}
                          >
                            <img
                              className="imga"
                              src={HDi}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={HDh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={HDd}
                              alt="image-icon"
                            />
                            {enhanceOption === "2" && (
                              <h5>{t("enhance_option2")}</h5>
                            )}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool1?.boolean === true &&
                                  activeLearnBool1?.id === 3
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <LearnPopups
                                heading={t("enhance_option2")}
                                content={t("enhance_option2_content")}
                                styles="0px"
                                image={upscaleinfo}
                                position={activeLearnBool1?.position}
                              />
                            </Portal>
                          </button>
                          <button
                            className={`toolset__section__tools__main__upscale__selection__sub__sec ${
                              enhanceOption === "3" ? "active" : ""
                            }`}
                            onClick={() => handleEnhanceSelection("3")}
                            // disabled={checkIfSelectionIsDisabled()}
                            // style={{
                            //   cursor: checkIfSelectionIsDisabled()
                            //     ? "progress"
                            //     : "pointer",
                            // }}
                            onMouseEnter={(event) => handleMouseEnter(4, event)}
                            onMouseLeave={(event) => handleMouseLeave(4, event)}
                          >
                            <img
                              className="imga"
                              src={characteri}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={characterh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={characterd}
                              alt="image-icon"
                            />
                            {enhanceOption === "3" && (
                              <h5>{t("enhance_option3")}</h5>
                            )}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool1?.boolean === true &&
                                  activeLearnBool1?.id === 4
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <LearnPopups
                                heading={t("enhance_option3")}
                                content={t("enhance_option3_content")}
                                styles="0px"
                                image={characterinfo}
                                position={activeLearnBool1?.position}
                              />
                            </Portal>
                          </button>
                        </div>
                      </div>
                      {localEnhanceOption === "1" && (
                        <div className="toolset__section__tools__main__upscale__extend__slider">
                          <Slider
                            // key={activeImageFidelityLevel}
                            value={currentValue}
                            step={1}
                            min={-4}
                            max={4}
                            onChange={handleExtendSliderChange}
                            marks={labels}
                            trackStyle={{
                              backgroundColor: trackColor,
                            }}
                            handleStyle={{
                              borderColor: trackColor,
                            }}
                            railStyle={{
                              backgroundColor: "#ccc",
                            }}
                            tooltip={{
                              formatter: tooltipFormatter,
                            }}
                            dotStyle={{
                              borderColor: "#677074",
                              borderWidth: "1px",
                            }}
                          />
                          <span>{extendValue}</span>
                        </div>
                      )}
                      {localEnhanceOption === "2" && (
                        <div className="toolset__section__tools__main__upscale__imagetype">
                          <div className="toolset__section__tools__main__upscale__imagetype__main">
                            <div
                              className="toolset__section__tools__main__upscale__imagetype__heading"
                              // key={imageTypeData}
                              // onMouseEnter={(event) =>
                              //   handleMouseEnter(1, event)
                              // }
                              // onMouseLeave={(event) =>
                              //   handleMouseLeave(1, event)
                              // }
                            >
                              <div
                                className={`toolset__section__tools__main__upscale__imagetype__heading__sec
                            ${languageActive === "ch" ? "ch" : ""} ${
                                  upscaleType === 1 ? "active" : ""
                                }`}
                                onClick={() => handleUpscaleType(1)}
                              >
                                <h5>{t("upscale_basic")}</h5>
                              </div>
                              <div
                                className={`toolset__section__tools__main__upscale__imagetype__heading__sec
                            ${languageActive === "ch" ? "ch" : ""} ${
                                  upscaleType === 2 ? "active" : ""
                                }`}
                                onClick={() => handleUpscaleType(2)}
                              >
                                <h5>{t("upscale_creative")}</h5>
                              </div>
                              {/* <Portal
                            style={{
                              display: `${
                                activeLearnBool1?.boolean === true &&
                                activeLearnBool1?.id === 1
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                          >
                            <LearnPopups
                              heading={t("hover_control_heading")}
                              content={t("hover_control_content")}
                              styles="0px"
                              image={controlTypeLevel}
                              position={activeLearnBool1?.position}
                            />
                          </Portal> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__video-ai">
                      <div className="toolset__section__tools__main__video-ai__base-img">
                        <div
                          className="toolset__section__tools__main__video-ai__base-img__heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("first_frame")}</h4>
                          {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(41, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 41
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("first_frame")}
                                  content={t("first_frame_tip1")}
                                  styles={
                                    languageActive === "ch" ? "220px" : "220px"
                                  }
                                  image={video}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )}
                        </div>
                        <div
                          className={`card ${
                            controlFocus?.id === 5 && controlFocus?.bool
                              ? "focus"
                              : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, 4)}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInput4"
                            style={{ display: "none" }}
                            onChange={handleFileChangeWithToolsetInput(4)}
                          />
                          <label
                            htmlFor="fileInput4"
                            style={{
                              cursor:
                                videoAILocalBase?.uploadedBaseImageUrl &&
                                !videoAILocalBase?.feedDesignBase64
                                  ? "progress"
                                  : "pointer",
                            }}
                          >
                            <div
                              className="card-content"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, 4)}
                            >
                              {videoAILocalBase?.uploadedBaseImageUrl ? (
                                <>
                                  <img
                                    className="uploaded"
                                    src={videoAILocalBase?.uploadedBaseImageUrl}
                                    draggable="false"
                                  />
                                  {videoAILocalBase?.feedDesignBase64 ===
                                    null ||
                                  videoAILocalBase?.feedDesignBase64 ===
                                    undefined ? (
                                    <>
                                      <div className="gradient-mask"></div>
                                      <div className="text-display">
                                        {t("uploading...")}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    className={`icon ${
                                      controlFocus?.id === 5 &&
                                      controlFocus?.bool
                                        ? "focus"
                                        : ""
                                    }`}
                                    src={
                                      controlFocus?.id === 5 &&
                                      controlFocus?.bool
                                        ? controlanim
                                        : baseicon
                                    }
                                    alt=""
                                  />
                                  <p>{t("first_frame_placeholder")}</p>
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToolSetComponent;
