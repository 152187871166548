import React, { useContext, useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/images/icons/airi-lab-beta_tag.png";
import userIcon from "../../assets/images/icons/user-icon.png";
import homeicona from "../../assets/images/icons/homea.png";
import homeicon from "../../assets/images/icons/home.png";
import galleryicon from "../../assets/images/icons/gallerybs.png";
import galleryicona from "../../assets/images/icons/gallerya.png";
import faqicona from "../../assets/images/icons/faqa.png";
import faqicon from "../../assets/images/icons/faq.png";
import accounticon from "../../assets/images/icons/account.png";
import accounticona from "../../assets/images/icons/accounta.png";
import newsicon from "../../assets/images/icons/news.png";
import newsicona from "../../assets/images/icons/newsa.png";
import contacticon from "../../assets/images/icons/contact.png";
import contacticona from "../../assets/images/icons/contacta.png";
import gpticon from "../../assets/images/icons/gpt.png";
import gpticona from "../../assets/images/icons/gpta.png";
import workspaceicon from "../../assets/images/icons/workspace.png";
import workspaceicona from "../../assets/images/icons/workspacea.png";
import notificationicon from "../../assets/images/icons/notification.png";
import notificationicona from "../../assets/images/icons/notificationa.png";
import globe from "../../assets/images/icons/language.png";
import globea from "../../assets/images/icons/languagea.png";
import upgradeicon from "../../assets/images/teams/icons/upgrade.png";
import dealicon from "../../assets/images/teams/icons/dealicon.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ClientDataContext } from "../../store/ClientData";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../store/UserDetailsStore";
import Logout from "../../assets/IconComponents/Logout";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import Confirm from "../confirmModal/confirm";
import { GetLatestProjectId } from "../../utils/apiCalls";

const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { responsiveData, isRedirect } = useContext(ResponsiveDataContext);
  const { clientData } = useContext(ClientDataContext);
  const { updateSocketData, handleShowSubscription } =
    useContext(UserSocketContext);
  const { isLoginUserDetails, handleNews, news } = useContext(UserContext);
  const { isTeamChanges, getUserDetailsOfteam } = useContext(TeamDesignContext);
  const { isTeamInvite, handleTeamNofications } = useContext(UserSocketContext);

  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language");
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));

  const [open, setOpen] = useState(false);
  const [confirmLogOut, setconfirmLogOut] = useState(false);
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(pathname ?? "");
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  const val1 = pathname === "/sign-in";
  const url = window.location.pathname;
  const substring1 = "workspace";
  let getval1 = url.includes(substring1);

  const hideHeader =
    pathname === "/terms-of-services" ||
    pathname === "/privacy-policy" ||
    pathname === "/renewal-policy";

  useEffect(() => {
    getLatestProjectId();
    getUserDetailsOfteam();
  }, [isTeamChanges]);

  const getLatestProjectId = () => {
    const teamId = isActiveTeam?.teamId !== 0 ? isActiveTeam?.teamId : 0;
    if (token) {
      GetLatestProjectId((res) => {
        if (res?.status === 200) {
          localStorage.setItem("getLatestProjectId", res?.data?.projectId);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, teamId);
    }
  };

  const handleSignIn = () => {
    navigate("/sign-in");
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleActiveLanguage = (e) => {
    if (e === 1) {
      localStorage.setItem("language", "ch");
      i18n.changeLanguage("ch");
      if(token) {
        window.location.reload();
      }
    } else {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
      if(token) {
        window.location.reload();
      }
    }
    setOpen(false);
  };

  const handleActiveMenu = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  const handleLogout = () => {
    setconfirmLogOut(!confirmLogOut);
  };

  const onConfirm = () => {
    setconfirmLogOut(false);
    updateSocketData({
      isSocketClose: true,
    });
    const getOldVersion = localStorage.getItem("currentVersion");
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("currentVersion", getOldVersion);
    localStorage.setItem("language", lang);
    window.location.href = "/sign-in";
  };

  const handleNavigateCheck = (e, url) => {
    if (isRedirect?.length !== 0 && getval1) {
      const confirmed = window.confirm(t("exit_message"));
      if (confirmed) {
        navigate_to(url);
      } else {
        window.history.pushState(null, window.location.pathname);
        e.preventDefault();
      }
    } else {
      navigate_to(url);
    }
  };

  const navigate_to = (url) => {
    if (pathname === "/projects") {
      localStorage.setItem("isWorkshop", false);
    }
    navigate(url);
  };

  const handleActiveLink = (link) => {
    if (isRedirect?.length === 0) {
      setActiveMenu(link);
    }
  };

  const handleHover = (isOpen) => {
    setHover(isOpen);
  };

  const handleHover1 = (isOpen) => {
    setHover1(isOpen);
  };

  const handleHover2 = (isOpen) => {
    setHover2(isOpen);
  };

  const handleHover3 = (isOpen) => {
    setHover3(isOpen);
  };

  const handleHover4 = (isOpen) => {
    setHover4(isOpen);
  };

  const faq = `${t("tutorial")} & ${t("airi_faq")}`;

  const [showFirstContent, setShowFirstContent] = useState(false);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setShowFirstContent(prev => !prev);
  //   }, 1200);
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div className="header">
      <div className="header__container">
        {!token ? (
          <div className="header__nav">
            <div className="header__nav__logo">
              {clientData?.logo !== undefined ? (
                <NavLink to="/" className="logo">
                  <img
                    className={"logo"}
                    src={clientData?.logo}
                    alt="AIRI-logo"
                  />
                </NavLink>
              ) : (
                <NavLink to="/" className="logo">
                  <img className={"logo"} src={logo} alt="AIRI-logo" />
                </NavLink>
              )}
            </div>
            <div className="header__nav__buttons">
              <Popover
                overlayClassName="header-popover-language"
                content={
                  <>
                    <h6
                      onClick={() => handleActiveLanguage(1)}
                      className={`language ${lang === "ch" ? "active" : ""}`}
                    >
                      简体中文
                    </h6>
                    <h6
                      onClick={() => handleActiveLanguage(2)}
                      className={`language ${lang === "en" ? "active" : ""}`}
                    >
                      English
                    </h6>
                  </>
                }
                arrow={false}
                trigger="click"
                placement="bottomRight"
                open={open}
                onOpenChange={handleOpen}
              >
                <button className="header__nav__buttons__language">
                  <img src={globea} className="globea" alt="globe-icon" />
                  <img src={globe} className="globed" alt="globe-icon" />
                  <h5>{lang === "en" ? "English" : "简体中文"}</h5>
                </button>
              </Popover>
              <button
                disabled={val1}
                className="header__nav__buttons__sign-in"
                onClick={() => handleSignIn()}
              >
                {t("signin")}
              </button>
            </div>
          </div>
        ) : (
          <div className="header__nav">
            <div className="header__nav__logo">
              {clientData?.logo !== undefined ? (
                <NavLink to="/" className="logo">
                  <img
                    className={"logo"}
                    onClick={(e) => handleNavigateCheck(e, "/")}
                    src={clientData?.logo}
                    alt="AIRI-logo"
                  />
                </NavLink>
              ) : (
                <NavLink to="/">
                  <img
                    onClick={(e) => handleNavigateCheck(e, "/")}
                    className={"logo"}
                    src={logo}
                    alt="AIRI-logo"
                  />
                </NavLink>
              )}
              <div className="header__nav__links">
                <Popover
                  overlayClassName="header-popover"
                  content={t("workshop")}
                  arrow={true}
                  trigger="hover"
                  placement="bottom"
                  open={hover}
                  onOpenChange={handleHover}
                >
                  <NavLink
                    to="/projects"
                    onClick={(e) => {
                      handleNavigateCheck(e, "/projects");
                      handleActiveLink("/projects");
                    }}
                  >
                    <img
                      className="active"
                      src={homeicona}
                      alt="workshop-icon"
                    />
                    <img
                      className="default"
                      src={homeicon}
                      alt="workshop-icon"
                    />
                  </NavLink>
                </Popover>
                <Popover
                  overlayClassName="header-popover"
                  content={t("galleries")}
                  arrow={true}
                  trigger="hover"
                  placement="bottom"
                  open={hover1}
                  onOpenChange={handleHover1}
                >
                  <NavLink
                    to="/galleries"
                    onClick={(e) => {
                      handleNavigateCheck(e, "/galleries");
                      handleActiveLink("/galleries");
                    }}
                  >
                    <img
                      className="active"
                      src={galleryicona}
                      alt="workshop-icon"
                    />
                    <img
                      className="default"
                      src={galleryicon}
                      alt="workshop-icon"
                    />
                  </NavLink>
                </Popover>
                <Popover
                  overlayClassName="header-popover"
                  content={faq}
                  arrow={true}
                  trigger="hover"
                  placement="bottom"
                  open={hover2}
                  onOpenChange={handleHover2}
                >
                  <NavLink
                    to="/tutorials"
                    onClick={(e) => {
                      handleNavigateCheck(e, "/tutorials");
                      handleActiveLink("/tutorials");
                    }}
                  >
                    <img
                      className="active"
                      src={faqicona}
                      alt="workshop-icon"
                    />
                    <img
                      className="default"
                      src={faqicon}
                      alt="workshop-icon"
                    />
                  </NavLink>
                </Popover>
              </div>
            </div>
            {lastProject !== null && lastProject !== undefined && (
              <div className="header__nav__workspace">
                <div className="header__nav__workspace__name">
                  <div className="header__nav__workspace__team-name">
                    <h5>{lastProject?.teamName}</h5>
                  </div>
                  <div className="header__nav__workspace__project-name">
                    <h5>{lastProject?.projectName}</h5>
                  </div>
                </div>
                <div className="header__nav__workspace__link">
                  <Popover
                    overlayClassName="header-popover"
                    content={t("workspace")}
                    arrow={true}
                    trigger="hover"
                    placement="bottom"
                    open={hover3}
                    onOpenChange={handleHover3}
                  >
                    <NavLink to={`/workspace/${lastProject?.projectId}`}>
                      <img
                        className="active"
                        src={workspaceicona}
                        alt="workshop-icon"
                      />
                      <img
                        className="default"
                        src={workspaceicon}
                        alt="workshop-icon"
                      />
                    </NavLink>
                  </Popover>
                </div>
                <div className="header__nav__workspace__link">
                  <Popover
                    overlayClassName="header-popover"
                    content={t("gpt_heading")}
                    arrow={true}
                    trigger="hover"
                    placement="bottom"
                    open={hover4}
                    onOpenChange={handleHover4}
                  >
                    <NavLink
                      to="/airi-bot"
                      onClick={(e) => {
                        handleNavigateCheck(e, "/airi-bot");
                        handleActiveLink("/airi-bot");
                      }}
                    >
                      <img
                        className="active"
                        src={gpticona}
                        alt="airi-gpt-icon"
                      />
                      <img
                        className="default"
                        src={gpticon}
                        alt="airi-gpt-icon"
                      />
                    </NavLink>
                  </Popover>
                </div>
              </div>
            )}
            <div className="header__nav__user">
              <div className="header__nav__user__team">
                {!hideHeader && (
                    <button
                    className={`upgrade-button deal-active`}
                    onClick={handleShowSubscription}
                  >
                    <div className="button-content">
                      <div 
                        className="upgrade_text"
                        style={{ display: showFirstContent ? 'flex' : 'none' }}
                      >
                        <img src={upgradeicon} alt="upgrade-icon" />
                        <span>{t("upgrade_button")}</span>
                      </div>
                      <div 
                        className="deal"
                        style={{ display: !showFirstContent ? 'flex' : 'none' }}
                      >
                        <img src={dealicon} alt="deal-icon" />
                        <span>{t("subscription_deal")}</span>
                      </div>
                    </div>
                  </button>            
                  )}
                <div
                  className="header__nav__user__team__notification"
                  onClick={handleTeamNofications}
                >
                  {isTeamInvite?.showRedDot === true && (
                    <div className="red-dot"></div>
                  )}
                  <img
                    className="default"
                    src={notificationicon}
                    alt="notification-icon"
                  />
                  <img
                    className="active"
                    src={notificationicona}
                    alt="notificationa-icon"
                  />
                </div>
              </div>
              <Popover
                overlayClassName="header-popover-language"
                content={
                  <>
                    <h6
                      onClick={() => handleActiveLanguage(1)}
                      className={`language ${lang === "ch" ? "active" : ""}`}
                    >
                      简体中文
                    </h6>
                    <h6
                      onClick={() => handleActiveLanguage(2)}
                      className={`language ${lang === "en" ? "active" : ""}`}
                    >
                      English
                    </h6>
                  </>
                }
                arrow={false}
                trigger="click"
                placement="bottomRight"
                open={open}
                onOpenChange={handleOpen}
              >
                <div
                  className={`header__nav__user__language ${
                    open ? "active" : ""
                  }`}
                >
                  <img className="default" src={globe} alt="globe-icon" />
                  <img className="active" src={globea} alt="globea-icon" />
                </div>
              </Popover>
              <div
                className={`header__nav__user__icon ${
                  isActiveMenu ? "active" : ""
                }`}
                onClick={handleActiveMenu}
              >
                <img
                  src={
                    isLoginUserDetails
                      ? isLoginUserDetails?.avatarUrl
                      : userIcon
                  }
                  alt="user-icon"
                  className="usericon"
                />
                <div className="header__nav__user__menu menu">
                  <div className="header__nav__user__menu__links">
                    <div className="header__nav__user__menu__link">
                      <NavLink
                        to="/account"
                        onClick={(e) => {
                          handleNavigateCheck(e, "/account");
                          handleActiveLink("/account");
                        }}
                        className={`${activeMenu === "/account" && "active1"}`}
                      >
                        <img
                          className="default"
                          src={accounticon}
                          alt="account-icon"
                        />
                        <img
                          className="active"
                          src={accounticona}
                          alt="account-icon"
                        />
                        <span>{t("account")}</span>
                      </NavLink>
                    </div>
                    <div className="header__nav__user__menu__link">
                      <a
                        onClick={() => handleNews()}
                        className={`${news ? "active" : ""}`}
                      >
                        <img
                          className="default"
                          src={newsicon}
                          alt="news-icon"
                        />
                        <img
                          className="active"
                          src={newsicona}
                          alt="news-icon"
                        />
                        <span>{t("news")}</span>
                      </a>
                    </div>
                    <div className="header__nav__user__menu__link">
                      <NavLink
                        to="/contact"
                        onClick={(e) => {
                          handleActiveLink("/contact");
                          handleNavigateCheck(e, "/contact");
                        }}
                        className={`${activeMenu === "/contact" && "active1"}`}
                      >
                        <img
                          className="default"
                          src={contacticon}
                          alt="contact-icon"
                        />
                        <img
                          className="active"
                          src={contacticona}
                          alt="contact-icon"
                        />
                        <span>{t("contact")}</span>
                      </NavLink>
                    </div>
                    <div className="header__nav__user__menu__link">
                      <a onClick={handleLogout}>
                        <Logout size={responsiveData ? "16" : "22"} />
                        <span>{t("logout")}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {confirmLogOut && (
        <Confirm
          isCancelRequired={true}
          confirmTitle={t("log_heading")}
          content={t("log_content1")}
          content1={t("log_content2")}
          onConfirm={onConfirm}
          onCancel={handleLogout}
          cancelButtonText={t("cancel")}
          buttonText={t("log_out_me")}
        />
      )}
    </div>
  );
};

export default Header;